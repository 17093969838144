
import {Routes} from '@angular/router';

//////////////////////////////////
// architect routes pages
//////////////////////////////////
import { ArchitectMyConsultationComponent } from './pages/architect/architect-my-consultation/architect-my-consultation.component';
import { ArchiAgloGPTComponent } from './pages/architect/archi-aglo-gpt/archi-aglo-gpt.component';
import { CarnetAdressesComponent } from './pages/architect/carnet-adresses/carnet-adresses.component';
import { ArchiSubscribe1Component } from './pages/architect/archi-subscribe1/archi-subscribe1.component';
import { ArchiSubscribeQuestionComponent } from './pages/architect/archi-subscribe-question/archi-subscribe-question.component';
import { ArchiSubscribe2Component } from './pages/architect/archi-subscribe2/archi-subscribe2.component';
import { CollaborateurSubscribe1Component } from './pages/architect/collaborateur-subscribe1/collaborateur-subscribe1.component';
import { ArchiSubscribe5Component } from './pages/architect/archi-subscribe5/archi-subscribe5.component';
import { ArchiNewCeComponent } from './pages/architect/archi-new-ce/archi-new-ce.component';
import { ArchiEditProfilComponent } from './pages/architect/archi-edit-profil/archi-edit-profil.component';
import { ArchiParrainageComponent } from './pages/architect/archi-parrainage/archi-parrainage.component';
import { ArchiDpgfComponent } from './pages/architect/archi-dpgf/archi-dpgf.component';
import { ArchiDpgfVersionComponent } from './pages/architect/archi-dpgf-version/archi-dpgf-version.component';
import { ArchiDpgfMinorVersionComponent } from './pages/architect/archi-dpgf-minor-version/archi-dpgf-minor-version.component';
import { ArchiDpgfResultComponent } from './pages/architect/archi-dpgf-result/archi-dpgf-result.component';
import { DpgfResultDetailsComponent } from './dpgf/pricing-results/dpgf-result-details/dpgf-result-details.component';
import { DpgfResultSummaryComponent } from './dpgf/pricing-results/dpgf-result-summary/dpgf-result-summary.component';
import { TeamCompareComponent } from './dpgf/pricing-results/dpgf-result-team/team-compare/team-compare.component';
import { DpgfResultComparisonComponent } from './dpgf/pricing-results/dpgf-result-comparison/dpgf-result-comparison.component';
import { AcvPageComponent } from './pages/architect/acv/acv-page/acv-page.component';
import { ArchiCeProjectArtisanComponent } from './pages/architect/archi-ce-project-artisan/archi-ce-project-artisan.component';
import { ArchiCeProjectDashboardComponent } from './pages/architect/archi-ce-project-dashboard/archi-ce-project-dashboard.component';
import { ArchiCeProjectPayementComponent } from './pages/architect/archi-ce-project-payement/archi-ce-project-payement.component';
import { ArchiNewDpgfComponent } from './pages/architect/archi-new-dpgf/archi-new-dpgf.component';
import { ArchiModifyCeComponent } from './pages/architect/archi-modify-ce/archi-modify-ce.component';
import { ArchiChantierFactureComponent } from './pages/architect/archi-chantier-facture/archi-chantier-facture.component';
import { ArchiDbComponent } from './pages/architect/archi-db/archi-db.component';
import { ArchiReefRedirectComponent } from './pages/architect/archi-reef-redirect/archi-reef-redirect.component';
import { StylesPageComponent } from './pages/architect/styles-page/styles-page.component';
import { ValidationAccountArchitectComponent } from './pages/architect/validation-account-architect/validation-account-architect.component';
import { SuggetedArtisansComponent } from './pages/architect/suggeted-artisans/suggeted-artisans.component';

// Re-Optimization
import { ArchiDpgfNewComponent } from './pages/architect/archi-dpgf-new/archi-dpgf-new.component';



//////////////////////////////////
// artisan routes pages
//////////////////////////////////
import { ArtisanSubscribeSignupComponent } from './pages/artisan/artisan-subscribe-signup/artisan-subscribe-signup.component';
import { ArtisanSubscribeGeneralComponent } from './pages/artisan/artisan-subscribe-general/artisan-subscribe-general.component';
import { ArtisanSubscribeAgencyInfoComponent } from './pages/artisan/artisan-subscribe-agency-info/artisan-subscribe-agency-info.component';
import { ArtisanSubscribeDetailComponent } from './pages/artisan/artisan-subscribe-detail/artisan-subscribe-detail.component';
import { ArtisanSubscribeFileComponent } from './pages/artisan/artisan-subscribe-file/artisan-subscribe-file.component';
import { ArtisanEditProfilComponent } from './pages/artisan/artisan-edit-profil/artisan-edit-profil.component';
import { ArtisanPriceDpgfComponent } from './pages/artisan/artisan-price-dpgf/artisan-price-dpgf.component';
import { ArtisanVersionDpgfComponent } from './pages/artisan/artisan-version-dpgf/artisan-version-dpgf.component';
import { ArtisanProjectsComponent } from './pages/artisan/artisan-projects/artisan-projects.component';
import { ArtisanCeProjectComponent } from './pages/artisan/artisan-ce-project/artisan-ce-project.component';
import { ArtisanChantierComponent } from './pages/artisan/artisan-chantier/artisan-chantier.component';
import { ArtisanBoosterProfileFormPageComponent } from './pages/artisan/artisan-booster-profile-form-page/artisan-booster-profile-form-page.component';
import { BoosterProfilePageComponent } from './pages/artisan/booster-profile-page/booster-profile-page.component';
import { DeleteAccountArtisanComponent } from './pages/artisan/delete-account-artisan/delete-account-artisan.component';

//////////////////////////////////
// reef routes pages
//////////////////////////////////
import { ArchiReefDocRedirectComponent } from './pages/architect/archi-reef-doc-redirect/archi-reef-doc-redirect.component';


//////////////////////////////////
// all routes pages
//////////////////////////////////
import { LoginPageComponent } from './pages/all/login-page/login-page.component';
import { SignUpEmailComponent } from './pages/all/sign-up-email/sign-up-email.component';
import { notFoundComponent } from './pages/all/404/404.component';
import { PublicBoosterProfileComponent } from './pages/artisan/public-booster-profile/public-booster-profile.component';
import { ErrorComponent } from './pages/all/error/error.component';
import { ForgotPasswordComponent } from './pages/all/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/all/reset-password/reset-password.component';
import { NoMultipleAccountsAllowedComponent } from './pages/all/no-multiple-accounts-allowed/no-multiple-accounts-allowed.component';


// Guards
import { ArchitectRouteGuard, ArtisanRouteGuard, ExternalRouteGuard, NewAccountArchitectRouteGuard, LoggedRouteGuard, AdminRouteGuard } from './_guard';
import { AdminInterfaceComponent } from './pages/admin/admin-interface/admin-interface.component';
import { AdminUsersComponent } from './pages/admin/admin-users/admin-users.component';
import { CreateUserPasswordComponent } from './pages/admin/create-user-password/create-user-password.component';
import { AdminDepartementComponent } from './pages/admin/admin-departement/admin-departement.component';
import { OpenidConnectedPageComponent } from './pages/all/openid-connected-page/openid-connected-page.component';
import { accessDeniedComponent } from './pages/all/403/403.component';
import { entityTooLargeComponent } from './pages/all/413/413.component';
import { serverError502Component } from './pages/all/502/502.component';
import { serverError500Component } from './pages/all/500/500.component';
import { LogosComponent } from './pages/admin/logos/logos.component';
import { BibliothequeComponent } from './pages/admin/bibliotheque/bibliotheque.component';
import { SignupArchitectComponent } from './pages/all/signup-architect/signup-architect.component';


export const routes: Routes = [
  // { path: 'login', component: LoginPageComponent},
  // { path: 'error', component: notFoundComponent},

  // Architect
  { path: 'architect',
    children: [
      { path: 'aglo-GPT', component: ArchiAgloGPTComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'new-subscription', component: SignupArchitectComponent,canActivate: [ExternalRouteGuard]  },
      { path: 'carnet-adresses', component: CarnetAdressesComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'subscribe', component: ArchiSubscribe1Component,canActivate: [ExternalRouteGuard] },
      { path: 'subscribe/verification', component: ArchiSubscribeQuestionComponent,canActivate: [ExternalRouteGuard] },
      { path: 'subscribe2', component: ArchiSubscribe2Component,canActivate: [ArchitectRouteGuard] },
      { path: 'collaborateur/subscribe1/:userId', component: CollaborateurSubscribe1Component,canActivate: [ExternalRouteGuard] },
      { path: 'subscribe5', component: ArchiSubscribe5Component ,canActivate: [ArchitectRouteGuard]},
      { path: 'my-consultations', component: ArchitectMyConsultationComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'new-ce-project', component: ArchiNewCeComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'profil', component: ArchiEditProfilComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'parrainage', component: ArchiParrainageComponent ,canActivate: [ArchitectRouteGuard]},
      // { path: 'dpgf/:dpgfId', component: ArchiDpgfComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'dpgf/:dpgfId', component: ArchiDpgfNewComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'dpgf-version/:dpgfId', component: ArchiDpgfVersionComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'dpgf-version-minor/:dpgfId', component: ArchiDpgfMinorVersionComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'dpgf/:dpgfId/result', component: ArchiDpgfResultComponent ,canActivate: [ArchitectRouteGuard], children: [
        { path: 'chiffrage', component: DpgfResultDetailsComponent },
        { path: 'comparatif', component: DpgfResultSummaryComponent },
        { path: 'hypothese', component: TeamCompareComponent },
        { path: 'graphique', component: DpgfResultComparisonComponent },
      ]},
      { path: 'ce-project/:ceProjectId/acv', component: AcvPageComponent,canActivate: [ArchitectRouteGuard] },
      { path: 'ce-project/:projectId/artisan', component: ArchiCeProjectArtisanComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'ce-project/:projectId', component: ArchiCeProjectDashboardComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'ce-project/:projectId/payement', component: ArchiCeProjectPayementComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'new-dpgf/:projectId', component: ArchiNewDpgfComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'modify-ce-project/:projectId', component: ArchiModifyCeComponent ,canActivate: [ArchitectRouteGuard]},
      { path: 'chantier-facture/:dpgfFactureId', component: ArchiChantierFactureComponent, canActivate: [ArchitectRouteGuard]},
      { path: 'archi-db', component: ArchiDbComponent,canActivate: [ArchitectRouteGuard]},
      { path: 'reef-redirect', component: ArchiReefRedirectComponent,canActivate: [ArchitectRouteGuard]},
      { path: 'styles', component: StylesPageComponent },
      { path: 'accountValidation', component: ValidationAccountArchitectComponent ,canActivate: [NewAccountArchitectRouteGuard]},
      { path: 'suggested-artisans/:ceProjectId', component: SuggetedArtisansComponent ,canActivate: [ArchitectRouteGuard]},
    ]
  },

  // Artisan
  { path: 'artisan',
    children: [
      { path: 'subscribe', component: ArtisanSubscribeSignupComponent,canActivate: [ExternalRouteGuard] },
      { path: 'subscribe2', component: ArtisanSubscribeGeneralComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'subscribe2-agency', component: ArtisanSubscribeAgencyInfoComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'subscribe3', component: ArtisanSubscribeDetailComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'subscribe4', component: ArtisanSubscribeFileComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'edit/profil', component: ArtisanEditProfilComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'dpgf/:dpgfPricingId', component: ArtisanPriceDpgfComponent,canActivate: [] },
      { path: 'dpgf-version/:dpgfPricingId', component: ArtisanVersionDpgfComponent,canActivate: [] },
      { path: 'project-list', component: ArtisanProjectsComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'project/:ceProjectInviteId', component: ArtisanCeProjectComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'dpgf-chantier/:dpgfFactureId', component: ArtisanChantierComponent, canActivate: [ArtisanRouteGuard]},  

      { path: 'booster-profile-forms', component: ArtisanBoosterProfileFormPageComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'booster-profile', component: BoosterProfilePageComponent,canActivate: [ArtisanRouteGuard] },
      { path: 'user/:userId/delete-account', component: DeleteAccountArtisanComponent, canActivate: [ArtisanRouteGuard]}
    ]
  },
  // Admin
  { path: 'admin',
    children: [
      { path: 'landingpage', component: AdminInterfaceComponent,canActivate: [AdminRouteGuard]},
      { path: 'createpassword/:userId', component: CreateUserPasswordComponent},
      { path: 'utilisateurs', component: AdminUsersComponent,canActivate: [AdminRouteGuard]},
      { path: 'departements', component: AdminDepartementComponent,canActivate: [AdminRouteGuard]},
      { path: 'logos', component: LogosComponent,canActivate: [AdminRouteGuard]},
      { path: 'bibliotheque', component: BibliothequeComponent,canActivate: [AdminRouteGuard]}
    ]
  },
  // admin on premise
  // { path: 'admin', loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdminModule)},

  // reef
  { path: 'document/:doctype/:docId', component: ArchiReefDocRedirectComponent},

  // All
  { path: '',
    children: [
      { path: '', component: LoginPageComponent,canActivate: [ExternalRouteGuard]  },
      { path: 'error', component: ErrorComponent},
      { path: 'authorize', component: OpenidConnectedPageComponent },
      { path: 'login', component: LoginPageComponent,canActivate: [ExternalRouteGuard]},
      { path: 'artisan-profil/:artisanId' , component: PublicBoosterProfileComponent,canActivate: [LoggedRouteGuard]},
      { path: 'error', component: ErrorComponent},
      { path: 'mot-de-passe-oublie', component: ForgotPasswordComponent,canActivate: [ExternalRouteGuard] },
      { path: 'reset-mot-de-passe/:token', component: ResetPasswordComponent,canActivate: [ExternalRouteGuard] },
      { path: 'no-multi-users', component: NoMultipleAccountsAllowedComponent },
      { path: 'access-denied', component: accessDeniedComponent },
      { path: 'entity-too-large', component: entityTooLargeComponent },
      { path: 'server-error', component: serverError500Component },
      { path: 'server-inaccessible', component: serverError502Component },
      { path: '', component: LoginPageComponent,canActivate: [ExternalRouteGuard] },
      { path: '**', component: notFoundComponent },
      
    ]
  }

  // architect
  // { path: 'architect', loadChildren: () => import('./pages/architect/architect.module').then(m => m.ArchitectModule) },
  // Artisan
  // { path: 'artisan', loadChildren: () => import('./pages/artisan/artisan.module').then(m => m.ArtisanModule)},
  // reef
  // { path: 'document/:doctype/:docId', component: ArchiReefDocRedirectComponent},
  //ALL
  // { path: '', loadChildren: () => import('./pages/all/all.module').then(m => m.AllModule)},
  // { path: 'admin', loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdminModule)},

];

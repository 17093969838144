import { Component, Input, Output, EventEmitter } from '@angular/core';
// modules
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $ :any;
@Component({
  selector: 'app-architect-paramters-headers-and-footers',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './architect-paramters-headers-and-footers.component.html',
  styleUrl: './architect-paramters-headers-and-footers.component.scss'
})
export class ArchitectParamtersHeadersAndFootersComponent {

  @Input() headersAndFooters: any = {};
  @Input() agencyLogoUrl: string = "";
  @Input() agencyName: string = "";
  @Output() saveHeadersAndFooters: EventEmitter<any> = new EventEmitter<any>();

  public variablesSections: any = [
    {
      title: "Projet",
      variablesList: [
        {label: "Opération", value: "%OPERATION%"},
        {label: "Nom du projet", value: "%NOM_PROJET%"},
        {label: "Image du projet", value: "%IMAGE_PROJET%"},
        {label: "Adresse du projet", value: "%ADRESSE_PROJET%"},
      ]
    },
    {
      title: "Document",
      variablesList: [
        {label: "Indice", value: "%INDICE%"},
        {label: "Date", value: "%DATE%"},
        {label: "Type de document", value: "%TYPE_DOCUMENT%"},
        {label: "Page actuelle", value: "%PAGE_ACTUELLE%"},
        {label: "Total de pages", value: "%TOTAL_PAGES%"},
      ]
    },
    {
      title: "MOE",
      variablesList: [
        {label: "Nom de l’agence du MOE", value: "%NOM_MOE%"},
        {label: "Logo du MOE", value: "%LOGO_MOE%"}
      ]
    }
  ];

  public formData: any = {};

  public previewData: any = {
    headers: {
      // left
      // center
      // right
    },
    footers: {
      // left
      // center
      // right      
    }
  };

  public headerSelectedAlignement: string = "left"; 
  public footerSelectedAlignement: string = "left";

  public headerFieldValue: string = "";
  public footerFieldValue: string = "";

  // 
  public variablesSection: string = ""; // header, footer

  ngOnInit(){
    this.formData = JSON.parse(JSON.stringify(this.headersAndFooters));

    this.headerFieldValue = this.formData.headers.left.text;
    this.footerFieldValue = this.formData.footers.left.text;

    this.fillPreviewData();
  }

  private fillPreviewData(){
    for(let key in this.formData){
      if(typeof this.formData[key] == "object"){        
        if(key in this.previewData){
          for(let sectionKey in this.formData[key]){
            
            let text = this.formData[key][sectionKey].text;
            let mappedText = this.variablesMapper(text);
            
            this.previewData[key][sectionKey] = mappedText;
          }
        }
      }
    }
  }

  private variablesMapper(text=""){
    let mappedText = text;

    const todayDate = new Date().toLocaleDateString();
    
    let variablesMap = {
      "%OPERATION%": "<span>Opération</span>",
      "%NOM_PROJET%": "<span>Nom du projet</span>",
      // "%IMAGE_PROJET%": "<span>Image du projet</span>",
      "%ADRESSE_PROJET%": "<span>Adresse du projet</span>",
      "%INDICE%": "<span>Indice A</span>",
      "%DATE%": `<span>${todayDate}</span>`,
      "%TYPE_DOCUMENT%": "<span>CCTP</span>",
      "%PAGE_ACTUELLE%": "<span>Page 1</span>",
      "%TOTAL_PAGES%": "<span>sur 10</span>",
      "%NOM_MOE%": `<span>${this.agencyName}</span>`,
      // "%LOGO%": `<img src='${this.agencyLogoUrl}' width='40' height='40' >`
    };
    
    
    for(let key in variablesMap){
      
      if(text.includes(key)){
        let mapValue = variablesMap[key];

        mappedText = mappedText.replace(key, mapValue);
        // regular text

        // image logo
      }

    }

    return mappedText;
  }


  public selectAlingement(key, value){
    if(key == 'header'){
      this.headerSelectedAlignement = value;
      this.headerFieldValue = this.formData.headers[value].text;
    }else{
      this.footerSelectedAlignement = value;
      this.footerFieldValue = this.formData.footers[value].text;
    }
  }
  // key : either header or footer
  // section either left, center or right
  // value either text or image 
  public toggleTextOrImage(key, value){
    if(key == 'header'){
      let selectedSection = this.headerSelectedAlignement;
      this.formData.headers[selectedSection].inputType = value;
    }else{
      let selectedSection = this.footerSelectedAlignement;
      this.formData.footers[selectedSection].inputType = value;
    }
  }

  public inputTyped(key, value){
    if(key == 'header'){
      const selectedAlignement = this.headerSelectedAlignement;
      this.headerFieldValue = value;

      // this.formData.headers[selectedAlignement].text = value;
    }else{
      const selectedAlignement = this.footerSelectedAlignement;
      this.footerFieldValue = value;
      // console.log("this.footerFieldValue");

      // this.formData.footers[selectedAlignement].text = value;
    }
  }

  public toggleSeperator(key){
    if(key == 'header'){
      let currentValue = this.formData.headers.showSeparatorBetweenHeadersAndBody;
      this.formData.headers.showSeparatorBetweenHeadersAndBody = !currentValue;
    }else{
      let currentValue = this.formData.footers.showSeparatorBetweenHeadersAndBody;
      this.formData.footers.showSeparatorBetweenHeadersAndBody = !currentValue;
    }
  }
  public openVariablesModal(section){
    this.variablesSection = section;

    $("#choose-variable-modal").modal("show");
  }

  public selectVariable(variable){
    let variableValue = variable.value;

    // append variable value into the header or footer field
    if(this.variablesSection == 'header'){
      this.headerFieldValue = `${this.headerFieldValue} ${variableValue}`;
    }else{
      this.footerFieldValue = `${this.footerFieldValue} ${variableValue}`;
    }

  }

  public changeColor(hexColor){
    this.formData.textColor = hexColor;
  }

  public cancelCurrentChanges(){
    this.formData = JSON.parse(JSON.stringify(this.headersAndFooters));
  }
  public emitSaveAffichageGeneral(){
    this.saveHeadersAndFooters.emit({key: 'headersAndFooters', data: this.formData});
  }

  
}

<div id="main-page-wrapper" class="flex">

  <!-- Left Sidebar
  ================================ -->
  <div class="left-sidebar width-30">
    
    <!-- Architect Agency Name -->
    <div class="sidebar-title">
      <p>Paramètres</p>
    </div>
    <!-- END Architect Agency Name -->

    <!-- Paramètres d’exports List Container
      =================================== -->
    <div id="parameters-menu" class="sidebar-menu-list-container">
      
      <!-- Section name -->
      <div class="section-name" [ngClass]="{'selected': currentParamter == 'export-settings'}" 
        (click)="changeTab('export-settings')">
        <p class="ag-bold pointer">Paramètres d’exports</p>
      </div>
      <!-- END Section name -->

      <!-- Emails List -->
      <div class="menu-list" *ngIf="currentParamter == 'export-settings'">
        <div class="single-menu-item pointer flex" 
          [ngClass]="{'selected': exportSettingsCurrentView == 'general-settings'}" 
          (click)="selectExportSettingsView('general-settings')">
          <p class="template-name no-margin margin-right-8">Affichage général</p>
        </div>
        <div class="single-menu-item pointer flex" 
          [ngClass]="{'selected': exportSettingsCurrentView == 'headers-and-footers'}" 
          (click)="selectExportSettingsView('headers-and-footers')">
          <p class="template-name no-margin margin-right-8">En-têtes et pieds de page</p>
        </div>
        <div class="single-menu-item pointer flex" 
          [ngClass]="{'selected': exportSettingsCurrentView == 'styles-and-text'}" 
          (click)="selectExportSettingsView('styles-and-text')">
          <p class="template-name no-margin margin-right-8">Styles de texte</p>
        </div>
        <div class="single-menu-item pointer flex" 
          [ngClass]="{'selected': exportSettingsCurrentView == 'sommaire-parameters'}" 
          (click)="selectExportSettingsView('sommaire-parameters')">
          <p class="template-name no-margin margin-right-8">Sommaire</p>
        </div>
      </div>
      <!-- END Emails List -->

    </div>
    <!-- END Paramètres d’exports List Container
      =================================== -->
      

    <!-- Emails Tempaltes List Container
      =================================== -->
    <div class="sidebar-menu-list-container">

      <!-- Section name -->
      <div class="section-name" [ngClass]="{'selected': currentParamter == 'emails-templates'}">
        <p class="ag-bold pointer" (click)="showEmailsList()">Modèle de mail</p>
      </div>
      <!-- END Section name -->

      <!-- Emails List -->
      <div class="menu-list" *ngIf="currentParamter == 'emails-templates'">
        <div class="single-menu-item flex" [ngClass]="{'selected': selectedEmailTemplate.id == template.id}" 
          *ngFor="let template of emailsTemplates">
          <p class="template-name pointer no-margin margin-right-8 ag-bold" (click)="selectEmailTemplate(template)">
            {{ template.name }}
          </p>
          <span class="automatic-badge" *ngIf="template.isDefault">Automatique</span>
        </div>

      </div>
      <!-- END Emails List -->

    </div>
    <!-- END Emails Tempaltes List Container
      =================================== -->
    
    <!-- Create new template button -->
    <div class="add-new-template-button">
      <div class="flex pointer" (click)="openNewEmailTemplateModal()">
        <img src="/assets/archi/add.svg" class="margin-right-8">
        <p class="no-margin">Créer un modèle de mail</p>
      </div>
    </div>
    <!-- END Create new template button -->


  </div>
  <!-- END Left Sidebar 
  ================================ -->


  <!-- Main Content 
  ================================ -->
  <div class="main-content width-70">

    <!-- Export Settings -->
    <div class="export-settings-wrapper" *ngIf="currentParamter == 'export-settings'">

      <!-- general settings -->
      <app-architect-paramters-affichage-general *ngIf="exportSettingsCurrentView == 'general-settings'"
        [affichageGeneral]="exportsSettings.affichageGeneral"
        (saveAffichageGeneral)="emitUpdateExportSettings($event)"
      ></app-architect-paramters-affichage-general>

      <!-- Headers & Footers -->
      <app-architect-paramters-headers-and-footers *ngIf="exportSettingsCurrentView == 'headers-and-footers'"
        [headersAndFooters]="exportsSettings.headersAndFooters"
        [agencyLogoUrl]="agencyLogoUrl"
        [agencyName]="agencyName"
        (saveHeadersAndFooters)="emitUpdateExportSettings($event)"
      ></app-architect-paramters-headers-and-footers>

      <!-- Styles & Texts -->
      <app-architect-paramter-styles-and-text *ngIf="exportSettingsCurrentView == 'styles-and-text'"
        [stylesAndText]="exportsSettings.stylesAndText"
        (saveStylesAndText)="emitUpdateExportSettings($event)"
      ></app-architect-paramter-styles-and-text>

      <!-- Styles & Texts -->
      <app-architect-paramter-sommaire *ngIf="exportSettingsCurrentView == 'sommaire-parameters'"
        [sommaire]="exportsSettings.sommaire"
        (saveSommaire)="emitUpdateExportSettings($event)"
      ></app-architect-paramter-sommaire>      

    </div>
    <!-- END Export Settings -->

    <!-- Emails Templates -->
    <div *ngIf="currentParamter == 'emails-templates'">
      <app-architect-emails-tempaltes-editor
        [emailsTemplatesSenderName]="emailsTemplatesSenderName"
        [emailsTemplates]="emailsTemplates"
        (updateEmailTemplates)="emitUpdateEmailTemplates($event)"
      ></app-architect-emails-tempaltes-editor>
    </div>
    <!-- END Emails Templates -->


  </div>


  </div>
<div id="parameters-headers-and-footer">

  <div class="sides-wrapper flex">

    <!-- preview side -->
    <div class="preview-side width-50">

      <div id="preview-page">
        
        <!-- Header text -->
        <div class="header-text-container flex space-between center-items">
          <!-- <p>Projet démo</p> -->
          <p [innerHtml]="previewData.headers.left"></p>
          <!-- <p>BAM</p> -->
          <p [innerHtml]="previewData.headers.center"></p>
          <!-- <img src="/assets/aglo-logo-rond.svg" alt=""> -->
          <div [innerHtml]="previewData.headers.right"></div>
        </div>
        <!-- END Header text -->

        <!-- Footer text -->
        <div class="footer-text-container flex space-between center-items">
          <!-- <p>CCTP - Indice A - 09/07/2024</p> -->
          <p [innerHtml]="previewData.footers.left"></p>
          <p [innerHtml]="previewData.footers.center"></p>
          <p [innerHtml]="previewData.footers.right"></p>
        </div>
        <!-- END Footer text -->

      </div>

    </div>
    <!-- END preview side -->

    <!-- input values side -->
    <div class="input-values-side width-50">
      <h4 class="ag-text-18 ag-weight-700 margin-bottom-16">En-têtes et pieds de page</h4>
      
      <!-- header info container  -->
      <div class="single-paramters-section header-info-container">
        <p class="ag-text-16 ag-weight-700 margin-bottom-8">En-têtes</p>

        <!-- pills & button -->
        <div class="pills-and-variables-button flex space-between center-items">
          <!-- Pills -->
          <div class="pills-wrapper">
            <span class="single-pill" [ngClass]="{'selected': headerSelectedAlignement == 'left'}" 
              (click)="selectAlingement('header', 'left')">
              Gauche
            </span>
            <span class="single-pill" [ngClass]="{'selected': headerSelectedAlignement == 'center'}" 
              (click)="selectAlingement('header', 'center')">
              Centre
            </span>
            <span class="single-pill" [ngClass]="{'selected': headerSelectedAlignement == 'right'}" 
              (click)="selectAlingement('header', 'right')">
              Droite
            </span>
          </div>
          <!-- END Pills -->
          @if(formData.headers[headerSelectedAlignement].inputType == 'text'){
          <button class="ag-btn-tertiary-sm" (click)="openVariablesModal('header')">Utiliser des variables</button>
          }
        </div>
        <!-- END pills & button -->

        <!-- Image Or Text -->
        <div class="image-or-text-container flex margin-top-15">
          <div class="flex margin-right-20 pointer">
            <input type="radio" name="header-text-or-image" class="no-margin-top" value="text"  
              [(ngModel)]="formData.headers[headerSelectedAlignement].inputType"> 
            <span class="margin-left-10">Texte</span>
          </div>
          <div class="flex pointer">
            <input type="radio" name="header-text-or-image" class="no-margin-top" value="image"
              [(ngModel)]="formData.headers[headerSelectedAlignement].inputType"> 
            <span class="margin-left-10">Image</span>
          </div>
        </div>
        <!-- END Image Or Text -->

        <!-- Image Type Container -->
        @if(formData.headers[headerSelectedAlignement].inputType == 'image'){
        <div class="image-type-container padding-left-20 margin-top-10">
          <div>
            <input type="radio" name="header-image-type" value="agencyLogo" class="no-margin-top"
              [(ngModel)]="formData.headers[headerSelectedAlignement].selectedImage"> 
            <span class="margin-left-10">Logo</span>
          </div>
          <div>
            <input type="radio" name="header-image-type" value="projectImage" class="no-margin-top"
              [(ngModel)]="formData.headers[headerSelectedAlignement].selectedImage"> 
            <span class="margin-left-10">Image de projet</span>
          </div>      
        </div>
        }
        <!-- END Image Type Container -->

        <!-- textarea container -->
        @if(formData.headers[headerSelectedAlignement].inputType == 'text'){
        <div class="text-area-container">
          <textarea (change)="inputTyped('header', $event.target.value)">{{ headerFieldValue }}</textarea>
        </div>
        }
        <!-- END textarea container -->

        <!-- checkbox container -->
        <div class="checkbox-container flex center-items pointer" (click)="toggleSeperator('header')">
          <input type="checkbox" [checked]="formData.headers.showSeparatorBetweenHeadersAndBody">
          <p class="no-margin margin-left-15">Afficher un séparateur entre l’en-tête et le corps du document</p>
        </div>

      </div>
      <!-- END header info container  -->

      <!-- footer info container  -->
      <div class="single-paramters-section footer-info-container">
        <p class="ag-text-16 ag-weight-700 margin-bottom-8">Pieds de page</p>

        <!-- pills & button -->
        <div class="pills-and-variables-button flex space-between center-items">
          <!-- Pills -->
          <div class="pills-wrapper">
            <span class="single-pill" [ngClass]="{'selected': footerSelectedAlignement == 'left'}" 
              (click)="selectAlingement('footer', 'left')">Gauche
            </span>
            <span class="single-pill" [ngClass]="{'selected': footerSelectedAlignement == 'center'}" 
              (click)="selectAlingement('footer', 'center')">Centre
            </span>
            <span class="single-pill" [ngClass]="{'selected': footerSelectedAlignement == 'right'}" 
              (click)="selectAlingement('footer', 'right')">Droite
            </span>
          </div>
          <!-- END Pills -->


          @if(formData.footers[footerSelectedAlignement].inputType == 'text'){
          <button class="ag-btn-tertiary-sm" (click)="openVariablesModal('footer')">Utiliser des variables</button>
          }
        </div>
        <!-- END pills & button -->

        <!-- Image Or Text -->
        <div class="image-or-text-container flex margin-top-15">
          <div class="flex margin-right-20 pointer">
            <input type="radio" name="footer-text-or-image" class="no-margin-top" value="text"  
              [(ngModel)]="formData.footers[footerSelectedAlignement].inputType"> 
            <span class="margin-left-10">Texte</span>
          </div>
          <div class="flex pointer">
            <input type="radio" value="image" name="footer-text-or-image" class="no-margin-top"
              [(ngModel)]="formData.footers[footerSelectedAlignement].inputType"> 
            <span class="margin-left-10">Image</span>
          </div>
        </div>
        <!-- END Image Or Text -->

        <!-- Image Type Container -->
        @if(formData.footers[footerSelectedAlignement].inputType == 'image'){
        <div class="image-type-container padding-left-20 margin-top-10">
          <div>
            <input type="radio" name="footer-image-type" value="agencyLogo" class="no-margin-top"
              [(ngModel)]="formData.footers[footerSelectedAlignement].selectedImage"> 
            <span class="margin-left-10">Logo</span>
          </div>
          <div>
            <input type="radio" name="footer-image-type" value="projectImage" class="no-margin-top"
              [(ngModel)]="formData.footers[footerSelectedAlignement].selectedImage"> 
            <span class="margin-left-10">Image de projet</span>
          </div>      
        </div>
        }
        <!-- END Image Type Container -->

        <!-- textarea container -->
        @if(formData.footers[footerSelectedAlignement].inputType == 'text'){
        <div class="text-area-container">
          <textarea (change)="inputTyped('footer', $event.target.value)">{{ footerFieldValue }}</textarea>
        </div>
        }
        <!-- END textarea container -->

        <!-- checkbox container -->
        <div class="checkbox-container flex center-items pointer" (click)="toggleSeperator('footer')">
          <input type="checkbox" [checked]="formData.footers.showSeparatorBetweenHeadersAndBody">
          <p class="no-margin margin-left-15">Afficher un séparateur entre le corps du document et le bas de page</p>
        </div>

      </div>
      <!-- END footer info container  -->


      <!-- colors container  -->
      <div class="single-paramters-section colors-container">
        <p class="ag-text-16 ag-weight-700 margin-bottom-8">Couleur des textes</p>

        <!-- textarea container -->
        <div class="color-input-container flex center-items pointer"  (click)="colorSelector.toggle($event)">
          <div class="color-preview margin-right-8" [style.backgroundColor]="formData.textColor"></div>
          <div class="color-value">
            <span>{{ formData.textColor }}</span>
          </div>
        </div>
        <!-- END textarea container -->

      </div>
      <!-- END colors container  -->
      

      <!-- action Buttons -->
      <div class="action-buttons-container text-center margin-top-16">
        <button class="ag-btn-tertiary-sm margin-right-24" (click)="cancelCurrentChanges()">Annuler</button>
        <button class="ag-btn-primary-xs" (click)="emitSaveAffichageGeneral()">Enregistrer</button>
      </div>
      <!-- END action Buttons -->


    </div>
    <!-- END input values side -->

  </div>


</div>



<!-- Modals -->

<!-- Color selector -->
<p-overlayPanel #colorSelector [showCloseIcon]="true" [appendTo]="'body'" >
  <color-sketch 
    width='200px' 
    [color]="formData.textColor"
    (onChangeComplete)="changeColor($event.color.hex)"
  ></color-sketch>
</p-overlayPanel>
<!-- END Color selector -->

<!-- Variables Modal -->
<div id="choose-variable-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <!-- modal header -->
        <p class="ag-text-14 ag-weight-700 margin-bottom-16">Ajouter une variable</p>
        
        <!-- variables sections list -->
        <div class="variables-sections-list">
          <div class="single-variable-section" *ngFor="let section of variablesSections">
            <p class="section-heading ag-text-14 ag-weight-700 margin-bottom-8">{{ section.title }}</p>
            <ul class="no-padding">
              <li class="ag-text-12 ag-weight-400 pointer" 
                *ngFor="let variable of section.variablesList"
                (click)="selectVariable(variable)">
                {{ variable.label }}
              </li>
            </ul>
          </div>
        </div>
        

      </div>
      <div class="modal-footer">
        <button type="button" class="ag-btn-secondary-sm ag-text-14 ag-weight-700" data-dismiss="modal">Fermer</button>
      </div>
    </div>
  </div>
</div>

<!-- END Variables Modal -->
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Helpers } from './helpers'
import {map} from "rxjs/operators";

//Interface
import { userActionLogger } from '../_interfaces/logger.interface';
import { environment } from 'src/environments/environment';

declare var $:any;
declare var Tawk_API


@Injectable()
export class AuthService {
    public token: string;
    public licence: string;
    public createdAt: string;
    public currentEnvironment:boolean = false;
    public dateEndTrial: any;
    public premuimFeatures = {
      without : {
        tasks : false,
        collaborateur : false,
        programmeRelance : false,
        downloadExcel : false,
        totalProject : 0,
        lineNb : 1,
        cstb : false,
        cstbGeneralite  : false,
        cstbPoste : false,
        archiDbPostNb: 1,
        carbone:false
      },
      base : {
        tasks : false,
        collaborateur : false,
        programmeRelance : false,
        downloadExcel : false,
        totalProject : 10000,
        lineNb : 200,
        cstb : false,
        cstbGeneralite  : false,
        cstbPoste : false,
        archiDbPostNb:50,
        carbone:false
      },
      //change
      plus : {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : false,
        totalProject : 10000,
        lineNb : 500,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb: 200,
        carbone:false
      },
      "plus-trim" : {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : false,
        totalProject : 10000,
        lineNb : 500,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb: 200,
        carbone:false
      },
      "plus-annuel": {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : false,
        totalProject : 10000,
        lineNb : 500,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb: 200,
        carbone:false
      },
      premium : {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:false
      },
      "premium-annuel":{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:false
      },
      premiumn : {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:true
      },
      "premiumn-trim":{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:true
      },
      "premiumn-annuel":{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:true
      },
      max:{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb:10000,
        carbone:true
      },
      "max-trim":{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb:10000,
        carbone:true
      },
      "max-annuel":{
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : false,
        cstbGeneralite  : true,
        cstbPoste : false,
        archiDbPostNb:10000,
        carbone:true
      },
      custom : {
        tasks : true,
        collaborateur : true,
        programmeRelance : true,
        downloadExcel : true,
        totalProject : 10000,
        lineNb : 10000,
        cstb : true,
        cstbGeneralite  : true,
        cstbPoste : true,
        archiDbPostNb:10000,
        carbone:true
      }
    };
    public licenceObserver: Subject<string> = new Subject<string>();

    public features = new BehaviorSubject({
      tasks : false,
      collaborateur : false,
      programmeRelance : false,
      downloadExcel : false,
      totalProject : 10000,
      lineNb : 200,
      cstb : false,
      cstbGeneralite  : true,
      cstbPoste : true,
      archiDbPostNb:1,
      carbone:false
    })

    public currentUser:any;
    public userLoggedIn : boolean = false;
    constructor(private http: HttpClient,
                private router: Router,) {
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
        this.licenceObserver.next(currentUser.licence);
        this.licence = currentUser.licence || 'without';
        this.createdAt = currentUser.createdAt || '';
        this.features.next(this.premuimFeatures[currentUser.licence] || this.premuimFeatures.base)
        this.token = currentUser && currentUser.token;
        this.currentEnvironment = environment.onPremise;
        this.currentUser = currentUser
        if(!$.isEmptyObject(this.currentUser)) this.userLoggedIn = true
    }

    login(user:any): Observable<any> {
      let body = JSON.stringify(user)
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      console.log('user',user)
        return this.http.post('/api/v1/auth/login', body, {headers})
            .pipe(map((response: any) => {
              console.log('response',response)
              return this.saveResponseJson(response)
            }));
    }
    resetFeature(){
      this.features.next(this.premuimFeatures[this.licence] || this.premuimFeatures.base)
    }
    checkToken(){
      if(this.currentUser.token){
        let {headers} = new Helpers().getHeader();
        this.http.get('/api/v1/auth/check-token', {headers})
        .toPromise()
        .then(
          res => { // Success
          },
          msg => { // Error
            this.logout()
            this.router.navigate(['login'], { queryParams: { infoBubble: "login-required" }});
          }
        );
      }
    }
    validateEmail(email:string): Observable<any> {
      let body = JSON.stringify({email: email})
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/auth/email', body, {headers})
            .pipe(map((response: any) => {
                let validEmail = response && response.validEmail;
                return validEmail;
            }));
    }
    forgetPassword(email:string): Observable<any> {
      let body = JSON.stringify({email: email})
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/auth/forgot/password', body, {headers})
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    resetPassword(token:string,password:string): Observable<any> {
      let body = JSON.stringify({password: password,token:token})
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/auth/reset/password', body, {headers})
            .pipe(map((response: any) => {
                let done = response
                return done;
            }));
    }
    registerArchi(userRegisterData:any): Observable<boolean> {
      let body = JSON.stringify(userRegisterData)
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/architect/auth/signup', body, {headers})
            .pipe(map((response: any) => {
              return this.saveResponseJson(response)
            }));
    }
    registerArtisan(userRegisterData:any): Observable<boolean> {
      let body = JSON.stringify(userRegisterData)
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/artisan/auth/signup', body, {headers})
            .pipe(map((response: any) => {
              return this.saveResponseJson(response)
            }));
    }
    registerMoa(userRegisterData:any): Observable<boolean> {
      let body = JSON.stringify(userRegisterData)
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post('/api/v1/moa/auth/signup', body, {headers})
            .pipe(map((response: any) => {
              return this.saveResponseJson(response)
            }));
    }
    registerCollaborateur(userId:string,userRegisterData:any): Observable<boolean> {
      let body = JSON.stringify(userRegisterData)
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`/api/v1/collaborateur/auth/signup/${userId}`, body, {headers})
            .pipe(map((response: any) => {
              return this.saveResponseJson(response)
            }));
    }
    saveResponseJson(json) {
      // login successful if there's a jwt token in the response
      let token = json && json.token;
      let userData = json && json.user;
      let archiId = json && json.archiId;
      let artisanId = json && json.artisanId;
      let moaId = json && json.moaId;
      let clientProjectID = json && json.clientProjectID;
      let isAdmin = json && json.isAdmin;
      let isClient = json && json.isClient;
      let isCollaborateur = json && json.isCollaborateur;
      let hasDpgf = json && json.hasDpgf;
      let licence = json && json.licence;
      let accessCstb = json && json.accessCstb;
      let id = json && json.id;
      let idDemandeur = json && json.idDemandeur;
      let isAdminArchitect = json && json.isAdminArchitect;
      let isNewAccount = json && json.isNewAccount;
      let createdAt = json && json.createdAt;

      let lastAgloOpen = json && json.lastAgloOpen;
      let validationToken = json && json.validationToken;
      let descView = json && json.descView;
      let dateEndTrial = json && json.dateEndTrial;
      let step = json && json.step;

      let logger = new userActionLogger()
      if (token) {
          // set token property
          this.token = token;
          logger.setUserId(id)
          // store username and jwt token in local storage to keep user logged in between page refreshes
          userData.token = token;
          userData.archiId = archiId || '';
          userData.artisanId = artisanId || '';
          userData.moaId = moaId || '';
          userData.clientProjectID = clientProjectID;
          userData.isAdmin = isAdmin;
          userData.isClient = isClient;
          userData.isCollaborateur = isCollaborateur;
          userData.hasDpgf = hasDpgf;
          userData.isAdminArchitect= isAdminArchitect;
          userData.accessCstb= accessCstb;
          this.licence = licence;
          userData.id = id;
          userData.idDemandeur = idDemandeur;
          userData.isNewAccount = isNewAccount;
          userData.createdAt = createdAt;
          this.createdAt = createdAt;
          
          userData.lastAgloOpen = lastAgloOpen;
          userData.validationToken = validationToken;
          userData.descView = descView;
          userData.dateEndTrial = dateEndTrial;
          userData.step = step;
          if(!environment.onPremise){
          Tawk_API.visitor = {
            name  : userData.firstName + " " + userData.firstName,
            email : userData.email
          };
          }
          localStorage.setItem('currentUser', JSON.stringify(userData));
          this.currentUser = userData
          this.changeLoginStatus(true);
          // return true to indicate successful login
          return true;
      } else {
          this.changeLoginStatus(false);
          // return false to indicate failed login
          return false;
      }
    }
    changeLoginStatus(status: boolean){
        this.userLoggedIn = status;
    }
    logout(keepLoginCookie=false): void {
      if(!keepLoginCookie){
        this.updateUser(this.currentUser.id, {loginCookie:{}}).subscribe((res)=>{
          console.log('Log out successfully')
        });
      }
      // clear token remove user from local storage to log user out
      this.token = null;
      this.licence = "none"
      this.changeLoginStatus(false);
      this.currentUser = {}
      localStorage.removeItem('currentUser');
      localStorage.removeItem("hideWarningDemoDialog");      
      localStorage.removeItem("cgu_2021");      
      this.removeLoginCookie("authUserCookie");
    }
    private removeLoginCookie(cookieName){
      let documentCookies = document.cookie;
      /// get, set, delete cookies 
      // https://www.guru99.com/cookies-in-javascript-ultimate-guide.html
      if(documentCookies.includes(cookieName)){
        document.cookie = `${cookieName}= ;expires = Thu, 01 Jan 1970 00:00:00 GMT;`;
      }    
    }
    getUserLicence(){
      let {headers} = new Helpers().getHeader();
      this.http.get('/api/v1/user/licence', {headers})
      .toPromise()
      .then(
        (res:any) => {
          this.licence = (res.data||{}).licence;
          this.dateEndTrial = (res.data||{}).dateEndTrial;
          this.features.next(this.premuimFeatures[(res.data||{}).licence] || this.premuimFeatures.base)
          if((res.data||{}).licence!=this.currentUser.licence){
            this.currentUser.licence = (res.data||{}).licence;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          }
        },
        msg => { // Error
        }
      );
    }
    getUserLicenceStripeUser(){
      let {headers} = new Helpers().getHeader();
      return this.http.get('/api/v1/user/licence', {headers})
            .pipe(map((response: any) => {
                return response;
            }));
    }
    getProjectLicence(ceProjectId){
      let {headers} = new Helpers().getHeader();
      this.http.get(`/api/v1/ce-projects/${ceProjectId}/licence`, {headers})
      .toPromise()
      .then(
        (res:any) => {
          let licence = (res.data||{}).licence;
          if(licence == 'custom'){
            this.features.next(this.premuimFeatures[licence])
          }else{
            this.resetFeature()
          }
        },
        msg => { // Error
        }
      );   
    }
    updateUserInformation(userId,body){
      let {headers} = new Helpers().getHeader();
      return this.http.patch(`/api/v1/user/${userId}/update-user-info`,body, {headers})
            .pipe(map((response: any) => {
                let done = response;
                return done;
            }));
    }
    /// global updating give it a body & it will update those values, unlike the above one, removes some info
    public updateUser(userId,body){
      let {headers} = new Helpers().getHeader();
      return this.http.patch(`/api/v1/user-update/${userId}`,body, {headers})
        .pipe(map((response: any) => {
          let done = response;
          return done;
        }));
    }
    
    getImage(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get('/api/v1/user/image', options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    isClient():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.isClient || false ;
    }
    isArchitect():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return this.userLoggedIn && currentUser.archiId != null && currentUser.archiId != "" ;
    }
    currentStep():Number {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.step;
    }
    isArtisan():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.artisanId != null && currentUser.artisanId != "" ;
    }
    isCollaborateur():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.isCollaborateur || false ;
    }
    isAdmin():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.isAdmin || false ;
    }
    isNewAccount():boolean {
      var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
      return currentUser.isNewAccount || false ;
    }
  public getAuthUser(){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || undefined;
    return currentUser;
  }
  updatePassword(userId,body){
    let {headers} = new Helpers().getHeader();
    return this.http.patch(`/api/v1/user/${userId}/change-password`,body, {headers})
          .pipe(map((response: any) => {
              let done = response;
              return done;
          }));
  }
  deleteAccount(userId,body){
    let {headers} = new Helpers().getHeader();
    return this.http.post(`/api/v1/user/${userId}/delete-account`,body, {headers})
          .pipe(map((response: any) => {
              let done = response;
              return done;
          }));
  }
  validateAccount(userId,token){
    let {headers} = new Helpers().getHeader();
    return this.http.post(`/api/v1/user/${userId}/validate-account`,{token:token}, {headers})
          .pipe(map((response: any) => {
              let done = response;
              return done;
          }));
  }
  sendEmailValidationAccount(userId){
    let {headers} = new Helpers().getHeader();
    return this.http.post(`/api/v1/user/${userId}/send-validation-email`,{}, {headers})
          .pipe(map((response: any) => {
              let done = response;
              return done;
    }));
  }
  updateDefaultTva(userId,body){
    let {headers} = new Helpers().getHeader();
    return this.http.post(`/api/v1/user/${userId}/change-tva-rate`,body, {headers})
          .pipe(map((response: any) => {
              let done = response;
              return done;
          }));
  }
  getUser(userId): Observable<any> {
    let {headers} = new Helpers().getHeader();
      return this.http.get(`/api/v1/user/${userId}`, {headers})
          .pipe(map((response: any) => {
              let done = response.data
              return done;
          }));
  }
  public noMultiAccountsAllowed(){
    let {headers} = new Helpers().getHeader();
    return this.http.get('/api/v1/user/no-multiple-accounts-allowed', {headers})
      .pipe(map((response: any) => {
          let done = response.data
          return done;
      }));
  }
}

<div id="emails-template-editor" class="flex">

  <!-- Left Sidebar
  ================================ -->
  <div class="left-sidebar width-30" *ngIf="false">
    
    <!-- Architect Agency Name -->
    <div class="architect-name">
      <!-- <p>{{ architectName }}</p> -->
      <p>Paramètres</p>
    </div>
    <!-- END Architect Agency Name -->

    <!-- Emails Tempaltes List Container -->
    <div class="emails-templates-list-container">

      <!-- Section name -->
      <div class="section-name">
        <p class="ag-bold pointer" (click)="showEmailsList()">Modèle de mail</p>
      </div>
      <!-- END Section name -->


      <!-- Emails List -->
      <!-- <div class="emails-list">
        <div class="single-email-template-item flex" [ngClass]="{'selected': selectedEmailTemplate.id == template.id}" 
          *ngFor="let template of emailsTemplates">
          <p class="template-name pointer no-margin margin-right-8 ag-bold" 
            (click)="selectEmailTemplate(template)">
            {{ template.name }}
          </p> 
          <span class="automatic-badge" *ngIf="template.isDefault">Automatique</span>
        </div>

      </div> -->
      <!-- END Emails List -->

    </div>
    <!-- END Emails Tempaltes List Container -->
    
    <!-- Create new template button -->
    <div class="add-new-template-button">
      <div class="flex pointer" (click)="openNewEmailTemplateModal()">
        <img src="/assets/archi/add.svg" class="margin-right-8">
        <p class="no-margin">Créer un modèle de mail</p>
      </div>
    </div>
    <!-- END Create new template button -->


  </div>
  <!-- END Left Sidebar 
  ================================ -->


  <!-- Main Content 
  ================================ -->
  <div class="main-content width-100">

    <div class="section-title-container margin-bottom-32">
      <p class="ag-bold">Modèle de mail</p>
    </div>

    <div class="form-section flex space-between">

      <!-- Email Template Form -->
      <div class="email-template-form-container width-65">

        <!-- Emails Templates List Card -->
        <div class="emails-templates-list-card margin-bottom-16" *ngIf="!templateIsSelected">
          <div class="title">
            <p class="ag-text-12 ag-text-down-river">Modèle de mail</p>
          </div>
          <div class="list-container">

            <div class="single-mail-item flex space-between" *ngFor="let template of emailsTemplates">
              <div class="">                
                <p class="ag-text-14 ag-weight-500">{{ template.name }}</p>
              </div>
              <div class="flex">
                <div class="margin-right-24">
                  <span class="automatic-badge" *ngIf="template.isDefault">Par défaut</span>
                </div>
                <div class="dropdown-side">
                  
                  <div class="dropdown">
                    <button class="btn btn-secondary no-padding dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu no-padding" aria-labelledby="dropdownMenuButton">
                      <li class="dropdown-item pointer flex space-between" (click)="selectEmailTemplate(template)">
                        Éditer
                        <img src="/assets/dpgf/edit-pen.svg" class="margin-left-8">
                      </li>
                      <li class="dropdown-item pointer flex space-between" (click)="duplicateEmailTemplate(template)">
                        Dupliquer
                        <img src="/assets/duplicate-icon.svg" class="margin-left-8">
                      </li>
                      <li class="dropdown-item pointer flex space-between" (click)="removeEmailTemplate(template)" *ngIf="!template.isDefault">
                        Supprimer
                        <img src="/assets/remove-icon.svg" class="margin-left-8">
                      </li>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END Emails Templates List Card -->
        
        <!-- Sender Form -->
        <div class="sender-form-card" *ngIf="!templateIsSelected">
          
          <div class="width-70">
            <p class="ag-text-12">Nom de l’expéditeur pour les mails enregistrés</p>
            <input type="text" placeholder="L’équipe de Because Architecture Matters" class="margin-bottom-4" [(ngModel)]="emailsTemplatesSenderName">
            <p class="ag-text-12 grey-label">Notamment utilisé pour les mails d’envoi des dossiers de consultations</p>
            <button class="ag-btn-primary-xs margin-top-12" (click)="emitSaveEmailsTemplates('sender')">Enregistrer</button>
          </div>

        </div>
        <!-- EDN Sender Form -->
        
        <!-- Email Template Form -->
        <div class="template-form-card" *ngIf="templateIsSelected">
          
          <div class="input-item-container margin-bottom-12">
            <p class="ag-text-12 no-margin">Nom du modèle</p>
            <div class="input-wrapper relative" [ngClass]="{'disabled': selectedEmailTemplate?.isDefault}">
              <input type="text" placeholder="Envoi du DCE" class="margin-bottom-4" [(ngModel)]="selectedEmailTemplate.name" [disabled]="selectedEmailTemplate?.isDefault">
              <img src="/assets/archi/lockout.svg" class="lock-img" *ngIf="selectedEmailTemplate?.isDefault">
            </div>
            <p class="ag-text-12 no-margin grey-label">Exemple : “Prise de rendez-vous”, “Proposition de visite”, etc. </p>
          </div>

          <div class="input-item-container margin-bottom-12">
            <p class="ag-text-12">Objet du mail</p>
            <div class="input-wrapper relative">
              <input type="text" placeholder="Envoi du DCE" [(ngModel)]="selectedEmailTemplate.subject">
            </div>
          </div>

          <div class="input-item-container">
            <p class="ag-text-12">Corps du mail</p>
            <div class="input-wrapper relative margin-bottom-8">

              <app-editor #editor 
                class="cctp-editor editor"
                placeholder="ex: Bonjour..."
                Description
                name="name"
                [style]="{'height':'205px', 'background': 'white', 'border-radius': '4px'}"
                [(ngModel)]="selectedEmailTemplate.message"
                (onSelectionChange)="setRange($event)"
                (onTextChange)="onTextChange($event)"
              ></app-editor>

            </div>
            <p class="ag-text-12 no-margin grey-label">Exemple : “Bonjour %NOM_ENTREPRISE%</p>
          </div>

          <button class="ag-btn-primary-xs margin-top-12" (click)="emitSaveEmailsTemplates('update')">Enregistrer</button>

        </div>
        <!-- END Email Template Form -->

      </div>
      <!-- END Email Template Form -->

      <!-- Variables List -->
      <div class="variables-list-container width-30">

        <div class="description-card" *ngIf="!templateIsSelected">
          <h4 class="ag-text-18 ag-bold">Optimisez vos échanges avec les entreprises</h4>
          <p class="ag-text-16 margin-bottom-20">Gagnez du temps en personnalisant les emails envoyées aux entreprises aux différentes étapes de la consultation.</p>
          <p class="ag-text-16">Les modèles marqués “Par défaut” sont utilisés lorsque vous réalisez les actions associées.</p>
        </div>

        <div class="list-card" *ngIf="templateIsSelected">
          <!-- Title -->
          <div class="title-container flex">
            <img src="/assets/info-icon.svg" class="margin-right-16">
            <h4 class="no-margin">Variables utilisables</h4>
          </div>
          <!-- END Title -->

          <div class="description-text-container">
            <p class="no-margin-bottom">Cliquez sur une variable pour l’ajouter au contenu du modèle.</p>
          </div>

          <!-- Variables List -->
          <div class="variables-list">
            <div class="single-variable-item pointer margin-bottom-8" 
              *ngFor="let variable of variablesList" (click)="insertVariable(variable)">
              {{ variable }}
            </div>
          </div>
          <!-- END Variables List -->
        </div>        


      </div>
      <!-- END Variables List -->

    </div>

  </div>
  <!-- END Main Content 
  ================================ -->

</div>


<!-- Add New Email Template Modal -->
<div class="modal fade" id="new-email-template-modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="title ag-text-18 ag-bold margin-bottom-16">Nouveau modèle de mail</h6>
      </div>
      <div class="modal-body">
        
        <div class="input-wrapper relative width-100">
          <p class="margin-bottom-4">Nom du modèle</p>
          <input type="text" placeholder="Envoi du DCE" [(ngModel)]="newEmailTemplate.name">
          <p class="grey-label">Exemple : “Prise de rendez-vous”, “Proposition de visite”, etc.</p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn-cancel ag-btn-tertiary-sm ag-bold margin-right-16" data-dismiss="modal">Annuler</button>
        <button type="button" class="btn-accept ag-btn-tertiary-sm ag-bold" data-dismiss="modal" (click)="createNewEmailTemplate()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>
<!-- END Add New Email Template Modal -->
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild, ViewChildren, NO_ERRORS_SCHEMA } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import { DragulaService } from 'ng2-dragula'; 
import { ConfirmationService } from 'primeng/api';
import { ArchiDbService } from "../../../_services/archi-db.service";
import { ArchitectService } from '../../../_services/architect.service';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { CstbNavigationComponent } from '../../../all/cstb/cstb-navigation/cstb-navigation.component';
import { Observable, of, Subscription } from 'rxjs';
import * as actions from '../../../dpgf/dce-edition/_store/dpgf.actions';
import { AuthService, DpgfPostesService } from 'src/app/_services';
import { ArchiDbNavigationComponent } from 'src/app/dpgf/dce-edition/archi-db-navigation/archi-db-navigation.component';
// Componenets
import { NeedSubscriptionPopup2Component } from '../../need-subscription-popup2/need-subscription-popup2.component';
import { CstbNavigationLotComponent } from 'src/app/all/cstb/cstb-navigation-lot/cstb-navigation-lot.component';
import { ArchiDbPostComponent } from 'src/app/dpgf/dce-edition/archi-db-post/archi-db-post.component';
import { ArchiPostUploadComponent } from '../archi-post-upload/archi-post-upload.component';
import { MaxLibraryPostsModalComponent } from '../../max-library-posts-modal/max-library-posts-modal.component';
import { ArchiPostSliderComponent } from '../archi-post-slider/archi-post-slider.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { MessageService } from 'primeng/api';
import { ArchiCollabModalComponent } from '../archi-collab-modal/archi-collab-modal.component';
import { environment } from 'src/environments/environment';
const _ = require('lodash');

declare var $ :any;

@Component({
    standalone:true,
    selector: 'app-archi-lot-post',
    imports:[
      NgSharedModule,
      NeedSubscriptionPopup2Component,
      CstbNavigationLotComponent,
      ArchiDbPostComponent,
      ArchiPostUploadComponent,     
      ArchiDbNavigationComponent,
      MaxLibraryPostsModalComponent,
      ArchiPostSliderComponent,
      CstbNavigationComponent,
      ArchiCollabModalComponent
    ],
    providers: [DragulaService],
    templateUrl: './archi-lot-post.component.html',
    styleUrls: ['./archi-lot-post.component.scss'],
})
export class ArchiLotPostComponent implements OnInit {
  public projectLicence: string='none';
  loadingCstb: boolean;
  postArchiDb: any;
  showTable=true;
  showDossier=true;
  draggedIndex: string;
  draggedLotIndex: string;
  isFirstFolder: boolean;
  currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  loadingPostsSearch: boolean;
  licence: string;
  countPosts: any;
  countPostsOwn: any;
  movingPost: boolean;
  chosenPost: any;
  isAdmin: any;
  userData: any;
  hasCstb: boolean;
  accessCstb: boolean;
  premiumArray=["premium","premium-annuel","premiumn","premiumn-annuel","premiumn-trim","max","max-annuel","max-trim"]
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(
    private dragulaService: DragulaService, 
    private confirmationService: ConfirmationService,
    private Activatedroute: ActivatedRoute,
    private router: Router,
    private archiDbService: ArchiDbService,
    private architectService:ArchitectService,
    private store: Store,
    private dpgfPostesService :DpgfPostesService,
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    private messageService: MessageService
    ){ }
  public selectedPostsDuplicate=[];
  public displaySideDb;
  public searchTerm;
  public results=[];
  public lots=[];
  public rootLots;
  public lot;parentLot;
  public posts = [];
  public post;
  public displaySideCstb: boolean;
  public firstTime=true;isEmpty=false;isFromUrl=false;firstTimeLot=true;firstTimeNav=true;isInRoot=true;firstTimeDbArchi=true;showMaBiblioModal;
  public isPost = true;
  public adding = false;
  public loadingLots = true;loadingLot = false; loadingPosts = false; searching = false;
  public timer;
  public displaySidePost = false;
  public pageNum=0;numOfPages;dbNum=null;
  public selectedValues=[];i=0;selectedPosts=[];
  public pathArray = [{lot : {},name :"Bibliothèques"}];
  public archiId=JSON.parse(localStorage.getItem('currentUser')).archiId;
  public isAdministrator=JSON.parse(localStorage.getItem('currentUser')).isAdmin;
  public isAdminArchitect=JSON.parse(localStorage.getItem('currentUser')).isAdminArchitect;
  public currentLicence=this.authService.licence;
  public collabs;
  public postsSearch;
  public loadingPath;dpgfId;
  public names=[];
  public reloadArchiNavigation;
  public libraryMaxPosts: number;
  private subscriptions: Array<Subscription> = [];
  @ViewChild(ArchiDbNavigationComponent) archiDbNavigation: ArchiDbNavigationComponent;
  @ViewChild(CstbNavigationComponent) cstbNavigation: CstbNavigationComponent;
  @ViewChild('autoscroll') autoscroll: ElementRef;

  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if (event.key === 'CapsLock' && this.posts.length) {
  //     if(this.selectedValues.length){
  //       let index=this.posts.findIndex((post)=>post._id==this.selectedValues[this.selectedValues.length-1])
  //       this.selectedValues.push(this.posts[index+1]._id);
  //       this.selectedPostsDuplicate.push(this.posts[index+1]);
  //       this.cd.detectChanges();
  //     }
  //     else{
  //       this.selectedValues.push(this.posts[0]._id)
  //       this.selectedPostsDuplicate.push(this.posts[0]);
  //     }
  //   }
  // }
  ngOnInit(): void {
    // this.getCollabs();
    if (this.Activatedroute.snapshot.queryParamMap.get('idLot')) {
      this.getFirstLots(false);
      this.getLot(this.Activatedroute.snapshot.queryParamMap.get('idLot'),false,true)
    }
    else if(this.Activatedroute.snapshot.queryParamMap.get('archiId')){
      this.archiId = this.Activatedroute.snapshot.queryParamMap.get('archiId');
      this.isAdmin=this.Activatedroute.snapshot.queryParamMap.get('archiName')
      this.getLotAdmin(this.Activatedroute.snapshot.queryParamMap.get('archiId'),this.Activatedroute.snapshot.queryParamMap.get('archiName'));
    }
    else this.getFirstLots(true);
    this.onModalCloseDB()
    this.licence=this.authService.licence;
    // dragula
    this.dragulaService.createGroup("POSTS", {});
    this.dragulaService.drag("POSTS").subscribe(({el}) => {
      this.draggedIndex=el.id;
    });
    this.dragulaService.drop("POSTS").subscribe(value => {
    });
    this.dragulaService.createGroup("LOTS", {});
    this.dragulaService.drag("LOTS").subscribe(({el}) => {
      this.draggedLotIndex = el.id;
    });
    this.dragulaService.drop("LOTS").subscribe(value => {
    });
    this.authService.getUserLicenceStripeUser().subscribe(
      (data)=>{
        this.userData=data.data;
        // this.userData.hasReef=true;
      }
    )
    this.authService.features.subscribe((f)=>{
      this.hasCstb = f.cstb
    })
    this.accessCstb=_.get(this.authService,'currentUser.accessCstb',false)
    this.getRoutes();

    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })

    let createdAt = new Date(this.authService.createdAt);
    const comparisonDate = new Date('2021-11-12T00:00:00.000Z');

    if(createdAt > comparisonDate){
      this.libraryMaxPosts = 50;
    }else{
      this.libraryMaxPosts = this.authService.premuimFeatures[this.licence].archiDbPostNb;
    }
  }
  private getRoutes(){
    this.Activatedroute.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
      }
    });
  }
  trackById(index, item) {
    if (!item) return null;
    return item.id;
  }

  addSelected(post){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }
    else if(this.selectedValues.indexOf(post._id)!=-1){
      this.selectedValues.splice(this.selectedValues.indexOf(post._id),1);
      this.selectedPostsDuplicate.splice(this.selectedPostsDuplicate.map(function(e) { return e._id; }).indexOf(post._id),1)
    }
    else {
      this.selectedValues.push(post._id);
      this.selectedPostsDuplicate.push(post);
    }
  }

  // change lotId and page in url
  setUrl(lotId=this.lot._id,page=this.pageNum) {
    return this.router.navigate(['/architect/archi-db'], { queryParams: { idLot: lotId, page:page } });
  }

  getPath(lotId){
    this.archiDbService.getLotPath(lotId).subscribe((data)=>{
      this.pathArray = [{lot : {},name :"Bibliothèques"}]
      this.pathArray=this.pathArray.concat(data);
      if(data[0].lot.archiId==this.archiId) this.pathArray.splice(1,0,{lot : {},name :"Ma bibliothèque"})
      else this.pathArray.splice(0,1,{lot : {},name :".."})
    })
  }
  goToPath(i){
    this.searching=false;
    if(i==1 && this.pathArray[i].name=='Ma bibliothèque'){
      this.setFirstLot(0);
      this.lot=null;
      this.posts=null;
    }
    else if(i==1 && this.pathArray[i].name!='Ma bibliothèque' && this.pathArray[0].name!='..'){
      this.setFirstLot(this.dbNum);
    }
    else if(i>0){
      this.lot = this.pathArray[i].lot;
      this.getLot(this.lot._id,false);
      this.pathArray = this.pathArray.slice(0,i+1);
    }
    else if(i==0){
      this.isInRoot=true;
      this.isFirstFolder=false;
      this.lot=null;
      this.pathArray = [{lot : {},name :"Bibliothèques"}];
    }
    this.firstTimeNav=false;
  }
  // handle post row click events
  public openSidePostEvent(type, post) {
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }else if(type == "add post" && ["plus","plus-trim","plus-annuel"].includes(this.licence) && this.countPostsOwn>=this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }else{
      if (type === "delete-post") {
        this.displaySidePost = false;
      }
      else if (type === "modify lot") {
        this.isPost = false;
        this.adding = false;
        this.displaySidePost = true;
      }
      else if (type ==="add lot" && this.licence!="without"){
        this.isPost = false;
        this.adding = true;
        this.displaySidePost = true;
      }
      else if(type==="add post" && this.countPosts<this.libraryMaxPosts && this.licence!="without"){
        this.post={}
        this.isPost = true;
        this.adding = true;
        this.displaySidePost = true;
      }
      // else if((type==="add post" && this.countPosts>=max) || this.licence=="without"){
      //   $('#upgrade-modal').modal("show");
      // }
      else if(type==="delete"){
        this.displaySidePost = false;
      }
      else if(!type){
        this.post = post;
        this.isPost = true;
        this.adding = false;
        this.displaySidePost = true;
      }
      $('body').css('overflow-y', 'auto');
    }
  }

  // handle search post row click events
  public handleSearchClick(event, lot, post) {
    if (event.target.id != "lot") {
      this.openSidePostEvent('modify post', post)
    }
    else {
      this.searching=false;
      this.getLot(lot,false);
      if(!this.firstTimeLot) this.getPath(lot);
    }
  }

  public closeSidePost(event) {
    if (event.searching) this.searching=true;
    else if(event.postId && event.updatedPost.lot!=this.lot._id) {
      this.posts=this.posts.filter((post)=>post._id!=event.postId)
    }
    else if(Object.keys(event.updatedPost).length) {
      let index=this.posts.findIndex(post=>post._id==event.updatedPost._id);
      event.updatedPost.archiId=this.posts[index].archiId;
      event.updatedPost.createdBy=this.posts[index].createdBy;
      event.updatedPost.dateCreated=this.posts[index].dateCreated;
      event.updatedPost.dateModified=this.posts[index].dateModified;
      event.updatedPost.modifiedBy=this.posts[index].modifiedBy;
      event.updatedPost.lot=this.lot._id;
      event.updatedPost.lotTitle=this.lot.title;
      this.posts.splice(index,1,event.updatedPost);
    }
    this.displaySidePost = false;
    $("body").css("overflow","auto");
  }
    /************* Lot ***************/

  public getFirstLots(pushRoot=false) {
    this.loadingLots = true;
    this.subscriptions.push(
      this.archiDbService.getAllLot('',false,{dpgfId:this.dpgfId,archiId:this.archiId,idLot:this.Activatedroute.snapshot.queryParamMap.get('idLot')}).subscribe(
        (data) => {
          this.currentLicence=data.licence;
          if(pushRoot)this.pathArray=[{lot : {},name :"Bibliothèques"}];
          this.isFromUrl=false;
          // parsing collabs and there names
          let h=0;
          this.collabs=data.collabs;
          data.names[0].sharedWith.forEach(element => {
            this.names[h]=element;
            // this.names[h]['licence']=element.users[0].licence
            this.names[h]['licence']=((element||[]).users[0]||{}).licence
            h++;
          });
          // end parsing collabs
          // start parsing data.dbs
          let j=0,index;
          if(this.isAdministrator&&data.dbs[0].archiId && !this.archiId)this.archiId=data.dbs[0].archiId;
          let foundArchiLot=null,archiLot;
          this.rootLots=[{archiName:'',lots:[],licence:''}];
          for(var i=0;i<data.dbs.length;i++){
            if(data.dbs[i].archiId==this.archiId) foundArchiLot=j;
            if (i>0 && data.dbs[i].archiId!=data.dbs[i-1].archiId){
              if(data.dbs[i].archiId==this.archiId && !foundArchiLot) foundArchiLot=j+1;
              this.rootLots.push({archiName:'',lots:[]});
              j++;
            }
            this.rootLots[j]['lots'].push(data.dbs[i])
          }
          archiLot=this.rootLots[foundArchiLot]
          if(foundArchiLot!=null){
            this.firstTimeDbArchi=false;
            this.rootLots.splice(foundArchiLot,1);
            this.rootLots.unshift(archiLot);
            this.rootLots[0]['archiName']="Ma bibliothèque";
          }
          else this.rootLots.unshift({archiName:'Ma bibliothèque',lots:[]})
          if(this.rootLots.length>1 && this.names.length){
            let arrayToDelete=[];
            for(var k=1;k<this.rootLots.length;k++) {
              index=this.names.findIndex(x=>x._id==(this.rootLots[k].lots[0]||[]).archiId);
              if(index!=-1) {
                this.rootLots[k]['archiName']=((this.isVowel(this.names[index].name))? "Bibliothèque d'":"Bibliothèque de ")+this.names[index].name;
                this.rootLots[k]['licence']=this.names[index].licence;
              }
            }
            this.rootLots.forEach((element,index) => {
              if((((this.currentLicence=="plus" ||  this.currentLicence=="plus-trim" ||this.currentLicence=="plus-annuel") && this.premiumArray.includes(element.licence)) || (this.currentLicence=="base" && element.licence!="base")) || (this.currentLicence=="without") && index>0) arrayToDelete.push(index)
            })
            this.rootLots = this.rootLots.filter((element, index) => !arrayToDelete.includes(index));
          }
          if(this.rootLots.length>1 && !this.rootLots[1].archiName) this.rootLots.splice(1,1);
          this.isEmpty=(this.rootLots[0].lots.length==0)?true:false;
          this.isInRoot=pushRoot;
          this.loadingLots = false;
        },
        (err) => {
          console.log(err);
        }
      )
    )
  }

  public setFirstLot(i){
    this.lots=this.rootLots[i].lots;
    if(!(this.rootLots[i].lots.length==0 && this.rootLots[i].archiName=='Ma bibliothèque')){
      this.lot=this.lots[0];
      this.lot.parentLot='';
      // this.getPosts(this.lot._id);
    }
    this.pathArray=[{lot : {},name :"Bibliothèques"}];
    this.pathArray.push({lot:this.lots,name:this.rootLots[i].archiName})
    this.isInRoot=false;
    this.dbNum=i;
    this.isFirstFolder=true;
  }

  public deleteLot(lot) {
    if(this.licence!='without' || this.projectLicence !='none'){
      this.confirmationService.confirm({
        message: 'Êtes-vous sûr(e) de vouloir supprimer ce dossier ainsi que ses postes ? ',
        header: "Suppression d'un dossier",
        key: 'lot',
        accept: () => {
          this.subscriptions.push(
            this.archiDbService.deleteLot(lot._id).subscribe(
              (data)=>{
                this.lots = this.lots.filter((dos) => { return dos._id != lot._id; });
                if(this.lot && this.lot._id==lot._id) {
                  if(this.lots[0] && this.lots[0]._id) this.lot=this.lots[0];
                  else {
                    this.router.navigate(['/architect/archi-db']);
                    this.rootLots[0].lots=[];
                    this.lot=null;
                    this.lots=[];
                    this.posts=[];
                    this.isEmpty=true;
                  }
                  if(this.lot)this.getPosts(this.lot._id,0);
                }
                if(!lot.parentLot){
                  this.rootLots[0].lots=this.rootLots[0].lots.filter((dos) => { return dos._id != lot._id; });
                }
                if(this.rootLots[0].lots.length==0) this.isEmpty=true;
                this.messageService.add({ key: 'toast', severity: 'success', summary: "Suppression d'un dossier", detail: "Le dossier ainsi que ses postes ont été supprimés", life: 5000 });
                // segment event
                if(!environment.onPremise)window.analytics.track('delete_folder_db',{properties:{acValue: 'delete_folder_db'}});
              },
              (err)=>{
                this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur de suppression", detail: "Erreur serveur", life: 5000 });
              }
            )
          )
          }
      })
    }
    else $("#user-on-without-license-modal").modal('show');
  }

  public updateLotEvent(lot) {
    if(!lot.parentLot && !lot.type){
      let index=this.rootLots[0].lots.map(function(e) { return e._id; }).indexOf(this.lot._id);
      this.rootLots[0].lots.splice(index,1,lot)
      this.lot=lot;
      this.pathArray[this.pathArray.length-1].name=lot.title;
    }
    else if(lot.parentLot==this.lot.parentLot) {
      this.lot=lot;
      this.pathArray[this.pathArray.length-1].name=lot.title;
    }
    else if(!lot.parentLot && lot.type){
      this.rootLots[0].lots.push(lot);
      this.pathArray.pop();
      this.getLot(this.lot.parentLot,false);
    }
    else if (!lot.parent && !this.lot.parentLot){
      let index=this.rootLots[0].lots.map(function(e) { return e._id; }).indexOf(this.lot._id);
      this.rootLots[0].lots.splice(index,1)
      this.setFirstLot(0);
      this.lot=null;
      this.posts=null
    }
    else {
      this.getLot(this.lot.parentLot,false);
      this.pathArray.pop();
    }
  }

  public getLot(lotId,pushArray=true,fromUrl=false) {
    this.loadingLots=true;
    this.isFirstFolder=false;
    this.subscriptions.push(
      this.archiDbService.getLot(lotId).subscribe((data) => {
        this.lots=data.sousLots;
        this.lot=data;
        this.isInRoot=false;
        if (fromUrl) {
          this.getPath(data._id);
        }
        else if(pushArray) this.pathArray.push({lot:this.lot,name:this.lot.title})
        this.loadingLots=false;
        this.firstTimeLot=false;
        this.getPosts(data._id)
      })
    )
  }
  /************* Post ***************/

  public getPosts(idLot,page=0) {
    this.pageNum=page;
    this.searching = false;
    this.selectedValues=[]
    this.selectedPostsDuplicate=[]
    this.loadingPosts = false;
    let archiId=(this.dbNum && this.rootLots[this.dbNum].lots.length)?this.rootLots[this.dbNum].lots[0].archiId:'';
    this.setUrl(idLot,page).then(()=>{
      this.subscriptions.push(
        this.archiDbService.getAllPost(idLot,page,archiId).subscribe(
          (data) => {
            this.posts=data.data;
            let count=data.metadata.count;
            this.countPostsOwn=data.metadata.countOwn;
            var i = 0,p=0;
            this.countPosts=data.metadata.allCount;
            if(i>0 && this.firstTime) {
              this.firstTime= false;
            }
            this.numOfPages = Array(Math.floor(count/25)+1).fill(0).map((x,i)=>i);
            if(count && count%25==0) {
              this.numOfPages.pop();
            }
            this.loadingPosts = true;
          },
          (err) => {
            console.log(err);
          }
      ))
    }
    )}

  /******** Search **********/
  public search(event, timed) {
    this.searching = true;
    this.loadingPosts = false;
    this.loadingPostsSearch=true;
    let title = event.target.value;
    if (timed) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!title) {
          this.loadingPosts = true;
          this.searching = false;
        }
        else {
          this.postsSearch = [];
          this.archiDbService.getPostByTitle(title,this.lot._id).subscribe(
            (data) => {
              this.postsSearch = data;
              this.loadingPosts = true;
              this.loadingPostsSearch=false;
            },
            (err) => {
              console.log(err);
            }
          )
        }
      }, 200);
    }
  }
  public deleteListPost(){
    this.confirmationService.confirm({
      message: `Êtes-vous sûr(e) de vouloir supprimer ces ${this.selectedValues.length} postes ?`,
      header: 'Suppression de postes',
      key: 'deletePosts',
      acceptLabel : "Confirmer",
      rejectLabel : "Annuler",
      accept: () => {
        this.subscriptions.push(
          this.archiDbService.deleteMultiPost(this.lot._id, this.selectedValues).subscribe(
            (data)=>{
              // this.posts = this.posts.filter(post => { return this.selectedValues.indexOf(post._id)==-1 })
              if(!this.posts.length && this.pageNum) this.getPosts(this.lot._id,this.pageNum-1)
              else {
                this.loadingPosts = false;
                setTimeout(()=>{this.getPosts(this.lot._id,this.pageNum);},1000)
                // this.countPosts=this.countPosts-this.selectedValues.length;
              }

              this.messageService.add({ key: 'toast', severity: 'success', summary: "Suppression de postes", detail: "Les postes selectionnés ont été supprimés", life: 5000 });
              this.selectedValues=[];
              this.selectedPostsDuplicate=[];
              // segment event
              if(!environment.onPremise)window.analytics.track('delete_post_db',{properties:{acValue: 'delete_post_db'}});
            },
            (err)=>{
              this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur de suppression", detail: "Erreur serveur", life: 5000 });
            }
          ))
        }
    });
  }
  /******** Delete **********/
  public deletePost(postId) {
    if(this.authService.currentEnvironment && this.userData.isAdminArchitect || this.licence!='without' || this.projectLicence !='none'){
      this.confirmationService.confirm({
        message: 'Êtes-vous sûr(e) de vouloir supprimer ce poste ? ',
        header: 'Supression de poste',
        key: 'post',
        acceptLabel : "Oui",
        rejectLabel : "Non",
        accept: () => {
          this.subscriptions.push(
            this.archiDbService.deletePost(this.lot._id, postId).subscribe(
              (data) => {
                // this.posts = this.posts.filter(post => { return post._id != postId })
                if(!this.posts.length && this.pageNum) this.getPosts(this.lot._id,this.pageNum-1)
                else {
                  this.loadingPosts=false;
                  setTimeout(()=>{this.getPosts(this.lot._id,this.pageNum);},1000)
                  // this.countPosts--;
                }
                this.messageService.add({ key: 'toast', severity: 'success', summary: "Suppression de postes", detail: "Le poste a été supprimé", life: 5000 });
                // segment event
                if(!environment.onPremise)window.analytics.track('delete_post_db',{properties:{acValue: 'delete_post_db'}});
              },
              (err) => {
                console.log(err)
              }
            )
          )
        }
      });
    }
    else if(this.authService.currentEnvironment && this.userData.isAdminArchitect || (this.licence=='without' && this.projectLicence =='none'))$("#user-on-without-license-modal").modal('show');

  }
  public newLotEvent(lot){
    // hide overlay bg
    $(".p-component-overlay").css("display","none");

    if(!this.rootLots[0].lots.length) {
      this.rootLots[0].lots.push(lot)
      this.lots=this.rootLots[0].lots;
      this.isEmpty=false;
    }
    else if(!lot.parentLot){
      this.rootLots[0].lots.push(lot);
      this.lots=this.rootLots[0].lots;
    }
    else{
      this.lots.push(lot);
    }
  }

  public selectPosteCstb(postes){
    this.selectedPosts=postes;
  }

  public closeSideCstb(){
    this.displaySideCstb=false;
    $("body").css("overflow","auto");
  }

  public importCstbPostes(){
    let max = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb
    this.selectedPosts[0].lotTitle=this.lot.title;
    this.selectedPosts=this.selectedPosts.slice(0,max-this.countPosts)
    this.loadingCstb=true;
    this.subscriptions.push(
      this.archiDbService.createMultiPost(this.lot._id,{posts:this.selectedPosts,lot:this.lot}).subscribe(
        (data) => {
          this.selectedPosts=[];
          setTimeout(()=>{
            this.getPosts(this.lot._id);
            this.closeSideCstb();
            this.cstbNavigation.goBack();
            this.loadingCstb=false;
            // if(!environment.onPremise)window.analytics.track('post_from_cstb',{properties:{acValue: 'post_from_cstb'}});
          },1000);
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Postes ajouté depuis CSTB", detail: "Les postes ont bien été ajoutés", life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_from_cstb_db',{properties:{acValue: 'add_post_from_cstb_db'}});
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: "Erreur serveur", life: 5000 });
        })
    )
  }
  public setPosteToAdd(post){
    if(this.countPostsOwn>=this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }
    else {
      this.showMaBiblioModal=true
      $('#import-post').modal("show")
    }
    this.postArchiDb=post;
  }
  public changePostOrder(event){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    let pos=event.findIndex(post=>post._id==this.posts[this.draggedIndex]._id);
    let draggedPost=this.posts[this.draggedIndex];
    let prevIndex=(pos)?event[pos-1].postOr:0;
    let nextIndex=(pos==event.length-1)?0:event[pos+1].postOr;

    this.showTable = false;
    this.posts[this.draggedIndex].postOr=(nextIndex)? (prevIndex+nextIndex)/2:prevIndex+10000
    this.posts.sort((a,b)=>a.postOr-b.postOr);
    setTimeout(()=>this.showTable = true)

    this.archiDbService.changePostOrder({prevIndex:prevIndex,nextIndex:nextIndex,draggedPostId:draggedPost._id,lastElemId:this.posts[this.posts.length-1]._id,firstElemId:this.posts[0]._id,page:this.pageNum},this.lot._id).subscribe(
      data=>{
      }
    )
  }
  public changeLotOrder(event){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    let updatedLots = event;

    let draggedLot = this.lots[this.draggedLotIndex];
    let draggedLotId = draggedLot._id;
    let prevIndex = Number(this.draggedLotIndex);
    let nextIndex = updatedLots.findIndex(lot => lot._id == this.lots[this.draggedLotIndex]._id);
    
    let parentLot = this.lots[0].parentLot;

    let data = {
      parentLot: parentLot,
      prevIndex: prevIndex,
      nextIndex: nextIndex,
      draggedLotId: draggedLotId
    };

    this.showDossier = false;
    this.lots = updatedLots;
    if(parentLot === "") this.rootLots[0].lots = updatedLots;
    setTimeout(()=>this.showDossier = true);
  
    this.archiDbService.changeLotOrder(data, draggedLotId).subscribe(
      data => {
      }
    );
  }
  public selectedPost(post){
    let posti={gid:post.gid,title:post.sugg,lotTitle:this.lot.title}
    this.selectedPosts.push(posti);
  }
  public importCstbPostese(){
    this.loadingCstb=true;
    let max = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb
    this.selectedPosts=this.selectedPosts.slice(0,max-this.countPosts)
    this.archiDbService.createMultiPost(this.lot._id,{posts:this.selectedPosts,lot:this.lot}).subscribe(
      (data) => {
        this.loadingCstb=false;
        this.selectedPosts=[];
        setTimeout(()=>{
          this.getPosts(this.lot._id);
          this.closeSideCstb();
          this.loadingCstb=false;
          this.searchTerm='';
        },1000);
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Poste ajouté depuis CSTB", detail: "Les postes ont bien été ajoutés", life: 5000 });
        // segment event
        if(!environment.onPremise)window.analytics.track('add_post_from_cstb_db',{properties:{acValue: 'add_post_from_cstb_db'}});
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: "Erreur serveur", life: 5000 });
      })
  }
  public searchPost(){
    this.subscriptions.push(
      this.dpgfPostesService.searchElasticSearchPostsCSTB(this.searchTerm,13)
              .subscribe(res => {
                console.log('searchPost',res)
                this.results=res.cstb;
                this.results=this.results.filter((post)=>post.type=='poste')
                this.cd.markForCheck();
              })
    )
  }
  public TotPrice(post){
    let price=parseInt(post.quantity) * parseInt(post.puht);
    if(!price) price=0;
    return price
  }
  public importArchiDbPostes(){
    let max = this.authService.premuimFeatures[this.authService.licence].archiDbPostNb
    let selectedPosts=this.archiDbNavigation.selectedPosts;
    selectedPosts=selectedPosts.slice(0,max-this.countPosts)
    this.loadingCstb=true;
    this.subscriptions.push(
      this.archiDbService.createMultiPost(this.lot._id,{posts:selectedPosts,lot:this.lot}).subscribe(
        data=>{
          setTimeout(()=>{
            this.getPosts(this.lot._id);
            this.closeSideDb();
            this.loadingCstb=false;
            this.reloadArchiNavigation=true;
            this.store.dispatch(actions.setSelectedPosts([]));
            $(".p-component-overlay").css("display","none");
          },1000);
          setTimeout(()=>{
            this.reloadArchiNavigation=false;
          },1500);

          this.messageService.add({ key: 'toast', severity: 'success', summary: "Poste ajouté depuis vos bibliothèques", detail: "Les postes ont bien été ajoutés", life: 5000 });
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_from_user_db',{properties:{acValue: 'add_post_from_user_db'}});
        }
      )
    )
  }
  public closeSideDb(){
    this.displaySideDb=false;
    $("body").css("overflow","auto");
  }
  public updatePostEvent(postId){
    this.posts=this.posts.filter(post=> post._id!=postId)
  }
  public isVowel(x) {
    let listofVowel= ['a','i','e','o','u','A','I','E','O','U'];
    return listofVowel.includes(x[0]);
  }
  public onModalCloseDB(){
    $('#import-post').on('hidden.bs.modal', () =>{
      this.showMaBiblioModal=false;
    })
  }
  public newPostEvent(post){
    if(this.posts.length%25==0 && this.pageNum) this.getPosts(this.lot._id,this.pageNum+1)
    else {
      this.posts.push(post);
      this.countPosts++;
    }
  }
  public uploadedPosts(){
    this.getPosts(this.lot._id,this.pageNum)
    $('#upload-post').modal("hide");
  }
  public displaySideDbF(){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }else if(["plus", "plus-trim", "plus-annuel"].includes(this.licence) && this.countPostsOwn>=this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }else{
      this.displaySideDb=true;
    }
  }
  public displaySideCstbF(){
    //change
    if((this.hasCstb || this.accessCstb) && this.countPosts<this.libraryMaxPosts){
      this.displaySideCstb=true;
    }else if(["without", "plus", "plus-trim", "plus-annuel", "max", "max-trim", "max-annuel"].includes(this.licence)){
      $("#cstb-license-modal").modal('show');
    }
  }
  public displaySideCstbE(){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }else if(["plus","plus-trim", "plus-annuel"].includes(this.licence) && this.countPostsOwn>=this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }else{
      $('#upload-post').modal("show");
    }
  }
  public redirectToPricingTable(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
    $('#upgrade-modal').modal("hide")
  }
  // V2
  public moveMultiPost(){

  }
  public duplicateMultiPost(){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }else if(["plus","plus-trim","plus-annuel"].includes(this.licence) && (this.countPostsOwn + this.selectedValues.length)>this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }else{
    this.confirmationService.confirm({
      message: `Êtes-vous sûr(e) de vouloir dupliquer ces ${this.selectedValues.length} postes ?`,
      header: 'Duplication de postes',
      key: 'duplicatePosts',
      acceptLabel : "Oui",
      rejectLabel : "Non",
      accept: () => {
        this.loadingPosts=false;
        this.subscriptions.push(
          this.archiDbService.createMultiPost(this.lot._id,{posts:this.selectedPostsDuplicate,lot:this.lot}).subscribe(
            (data) => {
              setTimeout(()=>{
                this.getPosts(this.lot._id);
                this.selectedPostsDuplicate=[];
                this.selectedValues=[];
                this.messageService.add({ key: 'toast', severity: 'success', summary: "Postes dupliqué", detail: "Les postes ont bien été ajoutés", life: 5000 });
              },1500);
              // segment event
              if(!environment.onPremise)window.analytics.track('add_post_from_cstb_db',{properties:{acValue: 'add_post_from_cstb_db'}});
            },
            (err)=>{
              this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: "Erreur serveur", life: 5000 });
            })
        )
          }})
    }
  }
  public duplicatePost(post){
    if(this.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
    }else if(["plus","plus-trim","plus-annuel"].includes(this.licence) && this.countPostsOwn>=this.libraryMaxPosts){
      $("#user-on-pro-license-post-library-modal #libraryMaxPosts").text(this.libraryMaxPosts);
      $("#user-on-pro-license-post-library-modal").modal('show');
    }else if(this.authService.currentEnvironment && this.userData.isAdminArchitect || !this.authService.currentEnvironment && this.licence.includes('premium')){
      this.confirmationService.confirm({
        message: `Êtes-vous sûr(e) de vouloir dupliquer ce poste ?`,
        header: 'Duplication de poste',
        key: 'duplicatePosts',
        acceptLabel : "Oui",
        rejectLabel : "Non",
        accept: () => {
          this.loadingPosts=false;
          this.subscriptions.push(
            this.archiDbService.createMultiPost(this.lot._id,{posts:[post],lot:this.lot}).subscribe(
              (data) => {
                setTimeout(()=>{
                  this.getPosts(this.lot._id);
                  this.messageService.add({ key: 'toast', severity: 'success', summary: "Poste dupliqué", detail: "Le(s) poste(s) ont bien été ajouté(s)", life: 5000 });
                },1500);
              },
              (err)=>{
                this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur poste", detail: "Erreur serveur", life: 5000 });
              })
          )
      }})
    }
  }
  public displayMovePost(postId){
    if(this.authService.currentEnvironment && this.userData.isAdminArchitect || !this.authService.currentEnvironment && this.licence!='without'){
      this.chosenPost=postId;
      $('#move-modal').modal("show")
    }
    else if (this.licence=='without' && this.projectLicence =='none')$("#user-on-without-license-modal").modal('show');
    else $('#upgrade-modal').modal("show");
  }
  public moveManyPosts(){
    this.chosenPost=null;
    $('#move-modal').modal("show")
  }
  public setChosenLot(lot){
    let postIds=[];
    this.movingPost=true;
    if(!this.chosenPost){
      postIds=this.selectedPostsDuplicate.map((val)=>val._id);
    }
    else postIds.push(this.chosenPost);
    this.subscriptions.push(
      this.archiDbService.updateLotParentMultiPost(lot._id,{posts:postIds,lot:lot}).subscribe(
        (data) => {
          setTimeout(()=>{
            this.getPosts(this.lot._id);
            this.selectedPostsDuplicate=[];
            this.selectedValues=[];
            this.movingPost=false;
            $('#move-modal').modal("hide")
            this.messageService.add({ key: 'toast', severity: 'success', summary: "Déplacement de poste", detail: "Le(s) poste(s) ont bien été déplacés", life: 5000 });
          },1000);
          // segment event
          if(!environment.onPremise)window.analytics.track('add_post_from_cstb_db',{properties:{acValue: 'add_post_from_cstb_db'}});
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur d'ajout poste", detail: "Erreur serveur", life: 5000 });
        })
    )
  }
  // admin biblio view
  private getLotAdmin(archiId,name){
    this.archiDbService.getAllLotAdmin({archiId:archiId,idLot:this.Activatedroute.snapshot.queryParamMap.get('idLot')}).subscribe((data)=>{
      this.rootLots=[{archiName:'',lots:[],licence:''}];
      this.rootLots[0]['lots']=data.dbs;
      this.rootLots[0]['archiName']=name;
      this.setFirstLot(0);
      this.collabs = data.collabs
      this.isInRoot=false;
      this.loadingLots = false;
    },
    (err)=>console.log(err)
    )
  }
  public showDropdown(posti){
    if(this.licence!='without' || this.projectLicence !='none') $('#action-dropdown-'+posti).toggle();
    else $("#user-on-without-license-modal").modal('show');
  }
  redirectReef=()=>{
    window.open('architect/reef-redirect?library=true', "_blank");
    // this.router.navigate(['architect/reef-redirect']);
  }
  goToReef=()=>{
    this.architectService.accessReef({docType:'',docId:''},false).subscribe(
      (data)=>{
        window.open(data, "_blank");
        // window.location.href = data;
      },(err)=>{
        console.log('err',err);
      }
    )
  }
  ngOnDestroy() {
    this.dragulaService.destroy('POSTS');
    this.dragulaService.destroy('LOTS');
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
  });
  }
}

import { Component } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import {FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import { Countries_codes } from "./countries-code";
import { Subscription } from 'rxjs';
import { ArchitectService, AuthService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup-architect',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './signup-architect.component.html',
  styleUrl: './signup-architect.component.scss'
})
export class SignupArchitectComponent {
  private validationMessages = {
    'email': {
      'required': 'Le mail est obligatoire.',
      // 'email':'Email invalide',
      "email": "",
      'pattern': 'Email invalide' // this is for regex pattern 
    },
  };
  public formErrors = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    // password2: ""
  };
  private subscriptions: Array<Subscription> = [];
  public passwordVisible: boolean = false;
  public subscriptionForm: UntypedFormGroup;
  public email:string = "";step:any  ="email-validation";countries_codes;
  signupForm: FormGroup;
  signupSecondForm: FormGroup;
  signupThirdForm: FormGroup;
  countries = [
    { name: 'France', dial_code: '+33', emoji: '🇫🇷', code: 'FR' },
    { name: 'Afghanistan', dial_code: '+93', emoji: '🇦🇫', code: 'AF' },
    { name: 'Aland Islands', dial_code: '+358', emoji: '🇦🇽', code: 'AX' },
    { name: 'Albania', dial_code: '+355', emoji: '🇦🇱', code: 'AL' },
  ];
  public validEmail:boolean = true;
  selectedCountry = this.countries[0]; // Default to the first country
  isDropdownOpen = false;
  phoneNumber = '';

  activities = [
    "Agence d’architecture 🪄", "Agence d’architecture d’intérieur ✨", "Économiste 📊 ", "Maître d'ouvrage privé 🎯",
    "MOA & MOE public 👨‍💻", "Bureau d’étude 📏", "Agence de décoration", "Paysagiste 🌳",
    "Urbaniste 🏙️", "Formateur/Enseignant 👩‍🏫", "Étudiant ‍👨‍🎓"
  ];

  sizes = [
    { display: "Seulement moi (1)", value: 1 },
    { display: "< 10", value: 2 },
    { display: "< 50", value: 10 },
    { display: "< 250", value: 50 },
    { display: "≥ 250", value: 250 },
  ];
  
  roles = [
    "Cadre dirigeant","Collaborateur","Bénévole","Autres"
  ]
  quantityDcePerYear = [
    { display: "1 - 3", value: 1 },
    { display: "4 - 10", value: 4 },
    { display: "11 - 20", value: 11 },
    { display: "30 - 50", value: 30 },
    { display: "50 +", value: 50 },
  ];
  
  quantityCollabsForDce = [
    { display: "1 - 2", value: 1 },
    { display: "3 - 10", value: 3 },
    { display: "10 - 20", value: 10 },
    { display: "20 +", value: 20 },
  ];
  provonances =  [
    "Recherche internet 💻","Réseaux sociaux ❤️","Recommandation d'un confrère 👯‍♀️","Article de presse 📰","Autre 👽"
  ]
  
  selectedActivity: string;userToken:string = '';
  selectedSize: string;

  noEmailValidation:boolean = false;firstName;lastName;
  constructor(private fuild: FormBuilder,private fb: UntypedFormBuilder,private authService: AuthService,private architectService: ArchitectService,private router: Router,private route: ActivatedRoute) {
    this.countries_codes =Countries_codes
    
  }
  ngOnInit(){
    this.getStepQuery()
    this.createForm();
    this.createSecondStepForm()
    this.createSecondStepForm2()
    this.createSecondStepForm3()
    this.chooseStep()
  }
  private createForm() {
    this.subscriptionForm = this.fb.group({
      email: [this.email, [
        Validators.required, 
        Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$")
      ]],
    });
    // this.subscriptions.push(
    //   this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    // )
    this.onValueChanged();
  }
  public createSecondStepForm(){
    this.signupForm = this.fuild.group({
      firstName: [this.firstName, [Validators.required]],
      lastName: [this.lastName, [Validators.required]],
      email: [{ value: this.email, disabled: this.userToken ? true : false }, [Validators.required]],
      name: ['', this.getValidators()],
      codePhone: [this.countries_codes[0].dial_code, [Validators.required]],
      phone: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      cgu: [false, [Validators.requiredTrue]],
    });

  }
  public createSecondStepForm2(){
    this.signupSecondForm = this.fuild.group({
      taille_entreprise: [''],
      typeStructure: ['', [Validators.required]],
    });
  }
  public createSecondStepForm3(){
    this.signupThirdForm = this.fuild.group({
      nb_collaborateur_dce: ['', [Validators.required]],
      dce_annuel: ['', [Validators.required]],
      discoveryMethod: ['', [Validators.required]],
    });
  }
  isEmailDisabled(): boolean {
    return this.userToken?true:false; // Disable if userToken is present
  }
  getValidators() {
    if (this.step === "create-userAccount" && this.userToken) {
      return []; // No validators
    } else {
      return [Validators.required]; // Apply required validator
    }
  }
  public chooseStep(){
    let step = this.authService.currentStep();
    if(step==1)this.step = "create-userAccount";
    if(step==2)this.step = "second-step";
    if(step==3)this.step = "third-step";
  }
  getStepQuery(): string | null {
    let step: string | null = null;
    this.route.queryParams.subscribe(params => {
      step = params['step'] || null; // Fetch and store the query parameter value
      this.userToken = params['token'] || "";
      this.email = params['email'] || "";
      this.firstName = params['firstName'] || "";
      this.lastName = params['lastName'] || "";
      if(step=="1")this.step = "create-userAccount";
      if(step=="2")this.step = "second-step";
      if(step=="3")this.step = "third-step";
        // this.router.navigate([], {
        //   queryParams: { step: null }, // Remove 'step'
        //   queryParamsHandling: 'merge' // Preserve other query params
        // });
    });
    console.log('step',step)
    
    return step;
  }
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  selectCountry(country: any): void {
    this.selectedCountry = country;
    this.signupForm.patchValue({"codePhone":country.dial_code})
    this.isDropdownOpen = false; // Close dropdown after selection
  }
  
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  public focusOutEmail(isSubmit){
    // if(!this.noEmailValidation && !this.router.snapshot.queryParamMap.get('token')){
      // if(!this.formErrors.email){
        this.subscriptions.push(
          this.authService.validateEmail(this.subscriptionForm.value.email)
          .subscribe(
            data => {
              this.validEmail = data;
              if(isSubmit && this.validEmail){
                this.signupForm.patchValue({email:this.subscriptionForm.value.email});
                this.step = "create-userAccount";
              }
            },
            error => {
              console.log(error)
            })
          )
        // } else if (scroll) {
        //   this.scrollToError()
        // }
    // }
  }
  register(){
    // this.loading = true;

    let isValid = this.signupForm.valid;
    let archiData = this.signupForm.getRawValue();
    archiData.phone = archiData?.codePhone.concat(archiData.phone);
    delete archiData.codePhone;
    archiData.archiId = "";
    // archiData.parrain = this.parrain;
    // archiData.parrainageId = this.parrainageId;
    archiData.userToken=this.userToken;
    // archiData.userToken=null;
    // archiData.source=this.source;
    if(this.step=="create-userAccount" && this.userToken)delete archiData.name;
    if(isValid){
      this.subscriptions.push(
        this.authService.registerArchi(archiData)
            .subscribe(
                data => {
                  if(this.step=="create-userAccount" && this.userToken)this.router.navigate(['/architect/my-consultations']);
                  else this.step = "second-step";
                  
                  // this.loading = false;
                  // if(this.userToken) {
                  //   this.logService.logEvent("Architect User Register")
                  //   this.router.navigate(['/architect/my-consultations']);
                  // }
                  // else {
                    // this.logService.logEvent("Architect Register")
                    // this.router.navigate(['/architect/subscribe2'],{ queryParams:{email: archiData.email,gotopay : this.gotopay}});
                  // }
                  // identify user segment
                  // let isParrain=(this.parrainageId!="");
                  // this.identify.identifySignUp(isParrain);
                  // this.messageService.add({ key: 'toast', severity: 'success', summary: 'Compte créé', detail: 'Votre compte a été crée', life: 5000 });

                },
                error => {
                    // this.loading = false;
                    // let err = (() => { try { return JSON.parse(error._body) } catch (something) { return error }})()
                    // this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: err.message, life: 5000 });
                })
      )
    }else{
      // this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers', life: 5000 });      
      // this.loading = false;
    }
  }
  save(num){
    // this.loading = true;
    console.log('save 2')
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let archiId = currentUser.archiId;
    let isValid = num==2 ? this.signupSecondForm.valid:this.signupThirdForm.valid;
    let agencyData ;
    if(num==2){
      if(this.signupSecondForm.value.typeStructure == "Formateur/Enseignant 👩‍🏫" || this.signupSecondForm.value.typeStructure == "Étudiant ‍👨‍🎓")this.signupSecondForm.patchValue({taille_entreprise: null});
      agencyData = {...this.signupSecondForm.value,step:3};
    } else if(num==3)agencyData = {...this.signupThirdForm.value,step:-1};
    // agencyData.step = Number(num);
    let archiInfo = {
      'firstName': currentUser.firstName,
      'lastName': currentUser.lastName,
      'phone': currentUser.phone,
      'email': currentUser.email
    }
    let data = {
      agencyData,
      archiInfo
    };
    console.log('agencyData',agencyData)
    console.log('this.signupSecondForm.valid',isValid)
    if(isValid){
      this.subscriptions.push(
        this.architectService.update(archiId, data)
            .subscribe(
                data => {
                  if(num == 3){
                    currentUser.step = -1
                    localStorage.setItem('currentUser',JSON.stringify(currentUser));
                    this.router.navigate(['/architect/accountValidation']);
                  } else if(num==2){
                    this.step = "third-step";
                    currentUser.step = 3
                    localStorage.setItem('currentUser',JSON.stringify(currentUser));
                  }

                    // this.loading = false;
                    // this.logService.logEvent("Architect Set Informations")
                    // if(this.gotopay == "true"){
                    //   this.router.navigate(['/architect/profil'], { queryParams: { page: 'licence' } });
                    // }else{
                    //   currentUser.isNewAccount ? this.router.navigate(['/architect/accountValidation']):this.router.navigate(['/architect/my-consultations']);
                    // }
                    // this.messageService.add({ key: 'toast', severity: 'success', summary: 'Vos informations principales ont été enregistrées', detail: 'Vos informations principales ont été enregistrées', life: 5000 });

                },
                error => {
                    console.log("error",error)
                    // this.loading = false;
                    // this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: error.message, life: 5000 });
                })
      )
    }else{
      // this.messageService.add({ key: 'toast', severity: 'error', summary: 'Erreur', detail: "Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers", life: 5000 });
// 
      // this.loading = false;
    }
  }
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
  subscribeWithOpenId(){
    const state = "archi"; 
    // window.location.href = `api/v1/login/openid/?type=${state}`;   
    window.location.href = `api/v1/auth/google/?type=${state}`;   

    window.open();
  }
  selectOption(controlName: string, value: any): void {
    this.signupSecondForm.get(controlName)?.setValue(value);
  }
  selectOptionThirstStep(controlName: string, value: any): void {
    this.signupThirdForm.get(controlName)?.setValue(value);
  }
    // public  markFormAsTouched():void {
  //   if (!this.subscriptionForm) {
  //     return;
  //   }
  //   const form = this.subscriptionForm;
  //   for (const field in this.formErrors) {
  //     // clear previous error message (if any)
  //     this.formErrors[field] = '';
  //     const control = form.get(field);
  //     // control.markAsDirty();
  //       const messages = this.validationMessages[field];
  //       for (const key in control.errors) {
  //           this.formErrors[field] += messages[key] + ' ';
  //       }
  //   }
  // }
}

import { Component, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';

// export settings components
import { ArchitectParamtersAffichageGeneralComponent } from '../architect-paramters-affichage-general/architect-paramters-affichage-general.component';
import { ArchitectParamtersHeadersAndFootersComponent } from '../architect-paramters-headers-and-footers/architect-paramters-headers-and-footers.component';
import { ArchitectParamterStylesAndTextComponent } from '../architect-paramter-styles-and-text/architect-paramter-styles-and-text.component';
import { ArchitectParamterSommaireComponent } from '../architect-paramter-sommaire/architect-paramter-sommaire.component';

// emails templates components
import { ArchitectEmailsTempaltesEditorComponent } from '../architect-emails-tempaltes-editor/architect-emails-tempaltes-editor.component';

@Component({
  selector: 'app-architect-parametres-tab',
  standalone: true,
  imports: [
    NgSharedModule,
    ArchitectParamtersAffichageGeneralComponent,
    ArchitectParamtersHeadersAndFootersComponent,
    ArchitectParamterStylesAndTextComponent,
    ArchitectParamterSommaireComponent,
    ArchitectEmailsTempaltesEditorComponent
  ],
  templateUrl: './architect-parametres-tab.component.html',
  styleUrl: './architect-parametres-tab.component.scss'
})
export class ArchitectParametresTabComponent {

  @ViewChild(ArchitectEmailsTempaltesEditorComponent) architectEmailsTempaltesEditorComponent:ArchitectEmailsTempaltesEditorComponent;
  
  @Input() architectName: string = "";
  @Input() emailsTemplatesSenderName: string = "";
  @Input() emailsTemplates: any = [];
  @Input() exportsSettings: any = {};
  @Input() agencyLogoUrl: string = "";
  @Input() agencyName: string = "";
  @Output() updateEmailTemplates: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateExportsSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeParameterTab: EventEmitter<any> = new EventEmitter<any>();

  public currentParamter:string = "export-settings"; // export-settings, email-templates

  // Export settings current view
  public exportSettingsCurrentView: string = "general-settings";

  // emails tempaltes current view
  public emailsTempaltesCurrentView: string = "";

  public selectedEmailTemplate: any = {
    id: "",
    name: "",
    subject: "",
    message: "",
  };

  public selectExportSettingsView(view){
    this.exportSettingsCurrentView = view;
  }

  public selectEmailTemplate(emailTemplate){
    // call child function
    this.architectEmailsTempaltesEditorComponent.selectEmailTemplate(emailTemplate);

    // this.templateIsSelected = true;
    this.selectedEmailTemplate = emailTemplate;
  }
  public showEmailsList(){
    this.currentParamter = 'emails-templates';

    // call child function
    this.architectEmailsTempaltesEditorComponent.showEmailsList();
  }
  public openNewEmailTemplateModal(){
    // call child function
    this.architectEmailsTempaltesEditorComponent.openNewEmailTemplateModal();
  }
  public changeTab(tab){
    this.currentParamter = tab;
  }

  public emitUpdateEmailTemplates($event){
    this.updateEmailTemplates.emit($event);
  }

  public emitUpdateExportSettings(body){
    const { key, data } = body;
    this.exportsSettings[key] = data;

    this.updateExportsSettings.emit(this.exportsSettings);
  }

}

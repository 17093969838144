<div id="parameters-affichage-general">

  <h4 class="ag-text-18 ag-weight-700">Affichage général</h4>
  
  <!-- Margins -->
  <div class="margins-container">
    <p class="ag-text-12 no-margin-bottom">Marges</p>
    <div class="dropdown">
      <div class="pointer flex space-between center-items" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span>{{ affichageGeneral.marginsTitle }}</span>
        <img src="/assets/chevron-bottom.svg">
      </div>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li class="pointer" *ngFor="let margin of marginsList" (click)="selectMargin(margin)">
          <p>{{ margin.title }} </p>
          <p>{{ margin.description1 }}</p>
          <p>{{ margin.description2 }}</p>
        </li>
      </ul>
    </div>
  </div>
  <!-- END Margins -->

  <!-- Format De Date -->
  <div class="formate-date-container">
    <p class="ag-text-18 ag-weight-700 no-margin-bottom">Format de date</p>
    <div class="flex radios-container">
      <p class="margin-right-24 pointer" (click)="selectDateFormat('short')">
        <input type="radio" [checked]="affichageGeneral.dateFormat == 'short'">
        14/08/2024
      </p>
      <p class="pointer" (click)="selectDateFormat('long')">
        <input type="radio" [checked]="affichageGeneral.dateFormat == 'long'">
        14 Août 2024
      </p>
    </div>
  </div>
  <!-- END Format De Date -->

  <!-- Intitulés des documents -->
  <div class="intitules-des-documents">
    <p class="ag-text-18 ag-weight-700 margin-bottom-16">Intitulés des documents</p>
    
    <div class="sides-wrapper flex">
      <div class="nav-side width-20">
        <ul class="no-padding">
          <li [ngClass]="{'selected': selectedDocumentType == 'cctp'}" (click)="selectDocumentType('cctp')">
            <span>CCTP</span>
          </li>
          <li [ngClass]="{'selected': selectedDocumentType == 'dpgf'}" (click)="selectDocumentType('dpgf')">
            <span>DPGF</span>
          </li>
          <li [ngClass]="{'selected': selectedDocumentType == 'dqe'}" (click)="selectDocumentType('dqe')">
            <span>DQE</span>
          </li>
          <li [ngClass]="{'selected': selectedDocumentType == 'estimation'}" (click)="selectDocumentType('estimation')">
            <span>Estimation</span>
          </li>
          <li [ngClass]="{'selected': selectedDocumentType == 'recapitulatifEestimation'}" (click)="selectDocumentType('recapitulatifEestimation')">
            <span>Récapitulatif de l’estimation</span>
          </li>
        </ul>
      </div>
      <div class="inputs-side width-80">

        <!-- Intitulé pour page de garde -->
        <div class="single-input-wrapper margin-bottom-8">
          <p>Intitulé pour page de garde</p>
          <input type="text" 
            [value]="affichageGeneral.documentsTitles[selectedDocumentType].indexPageTitle" 
            (keyup)="inputChanged('indexPageTitle', $event.target.value)">
        </div>
        <!-- END Intitulé pour page de garde -->
        
        <!-- Intitulé pour en-têtes et pieds de page -->
        <div class="single-input-wrapper">
          <p>Intitulé pour en-têtes et pieds de page</p>
          <input type="text" 
            [value]="affichageGeneral.documentsTitles[selectedDocumentType].headersAndFootersTitle" 
            (keyup)="inputChanged('headersAndFootersTitle', $event.target.value)">
        </div>
        <!-- END Intitulé pour en-têtes et pieds de page -->


      </div>
    </div>

  </div>
  <!-- END Intitulés des documents -->
  
  <!-- action Buttons -->
  <div class="action-buttons-container text-center">
    <button class="ag-btn-tertiary-sm margin-right-24">Annuler</button>
    <button class="ag-btn-primary-xs" (click)="emitSaveAffichageGeneral()">Enregistrer</button>
  </div>
  <!-- END action Buttons -->

</div>
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';

// Components
import { Editor } from 'src/app/all/editor/editor.component';

// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/_services';

import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';

declare var $:any;

@Component({
  selector: 'app-architect-emails-tempaltes-editor',
  standalone: true,
  imports: [
    Editor,
    NgSharedModule
  ],
  templateUrl: './architect-emails-tempaltes-editor.component.html',
  styleUrl: './architect-emails-tempaltes-editor.component.scss'
})
export class ArchitectEmailsTempaltesEditorComponent {

  @ViewChild('editor') editorRef: Editor;

  @Input() architectName: string = "";
  @Input() emailsTemplatesSenderName: string = "";
  @Input() emailsTemplates: any = [];
  @Output() updateEmailTemplates: EventEmitter<any> = new EventEmitter<any>();

  public projectLicence: string='none';
  public range: any;

  public templateIsSelected: boolean = false;
  public selectedEmailTemplate: any = {
    id: "",
    name: "",
    subject: "",
    message: "",
  };
 
  public newEmailTemplate: any = {
    id: "",
    name: "",
    subject: "",
    message: "",
  };

  public variablesList: any = [
    // the spaces are added to keep a whitespace
    " %NOM_PROJET%",
    " %ADRESSE_PROJET%",
    " %CODE_POSTAL_PROJET%",
    " %VILLE_PROJET%",
    " %PAYS_PROJET%",
    " %TYPE_TRAVAUX_PROJET%",
    " %PROGRAMME_PROJET%",
    " %DATE_RENDU_OFFRES%",
    " %DATE_DÉMARRAGE_TRAVAUX%",
    " %TELEPHONE_MOE%",
    " %NOM_AGENCE_MOE%",
    " %PRENOM_MOE%",
    " %NOM_MOE%",
    " %NOM_ENTREPRISE%"
  ];

  constructor(
    private store: Store,
    public authService: AuthService
    ) {}

  ngOnInit() {
    // if(!environment.onPremise)window.analytics.page('pageview_parametres');
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  public selectEmailTemplate(emailTemplate){
    this.templateIsSelected = true;
    this.selectedEmailTemplate = emailTemplate;
  }
  public showEmailsList(){
    this.templateIsSelected = false;
    this.newEmailTemplate = {
      id: "",
      name: "",
      subject: "",
      message: "",
    };
  }

  public openNewEmailTemplateModal(){
    $("#new-email-template-modal").modal("show");
  }

  public createNewEmailTemplate(){
    // generate new id
    let emailTemplateId = this.getId("email_template");
    this.newEmailTemplate["id"] = emailTemplateId;

    // is default
    this.newEmailTemplate["isDefault"] = false;

    // show the newly selected email tempalte form
    // this.selectEmailTemplate(this.newEmailTemplate);

    // push the newly create template to templates list
    this.emailsTemplates.push(this.newEmailTemplate);

    // save the newly create email Template
    if(!environment.onPremise)window.analytics.track('create_email_template',{properties:{acValue: 'create_email_template'}});
    this.emitSaveEmailsTemplates("create");

    /// empty old values
    this.templateIsSelected = false;

    this.newEmailTemplate = {
      id: "",
      name: "",
      subject: "",
      message: "",
    };

  }

  public duplicateEmailTemplate(emailTemplate){
    let newEmailTemplate = {...emailTemplate};

    // generate new id
    let emailTemplateId = this.getId("email_template");
    newEmailTemplate["id"] = emailTemplateId;

    // is default = false
    newEmailTemplate["isDefault"] = false;

    // push the newly create template to templates list
    this.emailsTemplates.push(newEmailTemplate);

    if(!environment.onPremise)window.analytics.track('duplicate_email_template',{properties:{acValue: 'duplicate_email_template'}});
    // show the newly duplicated email tempalte form
    // this.selectEmailTemplate(newEmailTemplate);
  }
  
  public removeEmailTemplate(template){
    // DO NOT REMOVE Default Emails
    if(template.isDefault) return;

    // remove the email template
    this.emailsTemplates = this.emailsTemplates.filter(item => item.id != template.id);
    if(!environment.onPremise)window.analytics.track('delete_email_template',{properties:{acValue: 'delete_email_template'}});
    this.emitSaveEmailsTemplates("delete");
  }

  public emitSaveEmailsTemplates(status){
    if(this.authService.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    let newBody = {
      emailsTemplatesSenderName: this.emailsTemplatesSenderName,
      emailsTemplates: this.emailsTemplates
    };    
    if(!environment.onPremise && status =="update")window.analytics.track('edit_email_template',{properties:{acValue: 'edit_email_template'}});
    if(!environment.onPremise && status =="sender")window.analytics.track('edit_email_sender',{properties:{acValue: 'edit_email_sender'}});

    this.updateEmailTemplates.emit({newBody, status});
  }

  private getId(type){
    return type + '_' + Math.random().toString(36).substr(2, 9);
  }

  // Editor
  public setRange(event){
    const { range } = event;
    if(range){
      this.range = range;
    }
  }
  public onTextChange(data){
    const { htmlValue } = data;
    this.selectedEmailTemplate.message = htmlValue;
  }
  public insertVariable(variable) {
    this.editorRef.insertVariable({variable, range : this.range});
 }


}

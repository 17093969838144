import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/index';
import { environment } from "src/environments/environment"
 

@Injectable()
export class ExternalRouteGuard  {
  constructor(private router: Router,private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const isAuth = this.authService.userLoggedIn;
      const SubscriptionNotFinished =  this.authService.currentStep() == -1;
      const currentRouteIsNewSubscription = state.url.includes("/architect/new-subscription");

      const goToNewSubscriptionRoute = isAuth&& !SubscriptionNotFinished && !currentRouteIsNewSubscription;

      console.log("isAuth : ", isAuth); 
      console.log("SubscriptionNotFinished : ", SubscriptionNotFinished); 
      console.log("currentRouteIsNewSubscription : ", currentRouteIsNewSubscription); 
      console.log("state.url : ", state.url); 

      console.log("goToNewSubscriptionRoute : ", goToNewSubscriptionRoute); 
        if(goToNewSubscriptionRoute){
          console.log('where 1 saad')
          this.router.navigate(['/architect/new-subscription'],{queryParams:{step:this.authService.currentStep()}});
          return false
        }else if(isAuth && !SubscriptionNotFinished&&currentRouteIsNewSubscription){
          console.log('where 2')
          return true
        }else if (!this.authService.userLoggedIn) {
            return true;
        }else if(environment.onPremise && this.authService.isAdmin()){
          this.router.navigate(['/admin/utilisateurs']);
        }
        else if(this.authService.isArchitect() || this.authService.isCollaborateur() || this.authService.isAdmin()){
          this.router.navigate(['/architect/my-consultations']);
        }
        else if(this.authService.isArtisan()){
          this.router.navigate(['/artisan/project-list']);
        }
        else{
          this.router.navigate(['/architecte/my-consultations']);
        }
        return false;
  }
}

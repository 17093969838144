<div id="styles-and-text">

  <div class="sides-wrapper flex">

    <!-- preview side -->
    <div class="preview-side width-50">

      <div id="preview-page">

        <div class="upper-section">

          <!-- Header text -->
          <div class="header-text-container flex space-between center-items">
            <p>Projet démo</p>
            <p>BAM</p>
            <img src="/assets/aglo-logo-rond.svg" alt="">
          </div>
          <!-- END Header text -->

          <!-- Texts styles container -->
          <div class="texts-styles-container">
            <h1 [ngStyle]="previewStyles['heading1styles']">LOT 0 - Généralités</h1>
            <h3 [ngStyle]="previewStyles['heading2styles']">0.0 Prescriptions techniques</h3>
            <h4 [ngStyle]="previewStyles['heading3styles']">0.0.1 Étendue des travaux</h4>
            <p [ngStyle]="previewStyles['normalStyles']">Les prestations à la charge des différents lots comprendront implicitement tous travaux nécessaires à une parfaite finition de l'ouvrage.</p>
            <p [ngStyle]="previewStyles.localisationStyles">
              <span [ngStyle]="previewStyles.localisationStyles.label">Localisation</span>
              <span [ngStyle]="previewStyles.localisationStyles.content">: Partout sur le projet</span>
            </p>
          </div>
          <!-- END Texts styles container -->

        </div>

        <!-- Footer text -->
        <div class="footer-text-container flex space-between center-items">
          <p>CCTP - Indice A - 09/07/2024</p>
          <p>Page 1 sur 10</p>
        </div>
        <!-- END Footer text -->

      </div>

    </div>
    <!-- END preview side -->

    <!-- input values side -->
    <div class="input-values-side width-50">
      <h4 class="ag-text-18 ag-weight-700 margin-bottom-16">Style des textes</h4>

      <!-- headings & paraghraphs container -->
      <div class="single-paramters-section headings-and-paraghraphs-container">
        <ul class="flex no-padding">
          <li [ngClass]="{'selected': selectedTextType == 'heading1styles'}" (click)="selectTextType('heading1styles')">
            <span class="ag-text-18">H1</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'heading2styles'}" (click)="selectTextType('heading2styles')">
            <span class="ag-text-18">H2</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'heading3styles'}" (click)="selectTextType('heading3styles')">
            <span class="ag-text-18">H3</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'heading4styles'}" (click)="selectTextType('heading4styles')">
            <span class="ag-text-18">H4</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'heading5styles'}" (click)="selectTextType('heading5styles')">
            <span class="ag-text-18">H5</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'normalStyles'}" (click)="selectTextType('normalStyles')">
            <span class="ag-text-18">Normal</span>
          </li>
          <li [ngClass]="{'selected': selectedTextType == 'localisationStyles'}" (click)="selectTextType('localisationStyles')">
            <span class="ag-text-18">Localisation</span>
          </li>
        </ul>
      </div>
      <!-- END headings & paraghraphs container -->

      <!-- Localisation Label font size container -->
      <div class="single-paramters-section localisation-section font-sizes-container margin-top-16" *ngIf="selectedTextType == 'localisationStyles'">
        <h4 class="ag-text-14 ag-weight-700 margin-bottom-16">Label</h4>
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Taille de la police</p>
        
        <div class="flex">
        
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue('localisationStylesLabel', 'fontSize')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue('localisationStylesLabel', 'fontSize')">
            </div>
            <div class="input-side">
              <input type="number" [value]="formData.localisationStyles.label.fontSize">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>

          <div class="font-types-input flex">
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.label.bold}"
              (click)="selectFontStyle('localisationStylesLabel', 'bold')">G</span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.label.italic}"
              (click)="selectFontStyle('localisationStylesLabel', 'italic')">I</span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.label.underline}"
              (click)="selectFontStyle('localisationStylesLabel', 'underline')">U</span>
          </div>

        </div>

      </div>
      <!-- END Localisation Label font size container -->
      <!-- Localisation Contenu font size container -->
      <div class="single-paramters-section localisation-section font-sizes-container margin-top-16" *ngIf="selectedTextType == 'localisationStyles'">
        <h4 class="ag-text-14 ag-weight-700 margin-bottom-16">Contenu</h4>
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Taille de la police</p>
        
        <div class="flex">
        
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue('localisationStylesContent', 'fontSize')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue('localisationStylesContent', 'fontSize')">
            </div>
            <div class="input-side">
              <input type="number" [value]="formData.localisationStyles.content.fontSize">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>

          <div class="font-types-input flex">
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.content.bold}"
              (click)="selectFontStyle('localisationStylesContent', 'bold')">
              G
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.content.italic}"
              (click)="selectFontStyle('localisationStylesContent', 'italic')">
              I
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.localisationStyles.content.underline}"
              (click)="selectFontStyle('localisationStylesContent', 'underline')">
              U
            </span>
          </div>

        </div>

      </div>
      <!-- END Localisation Contenu font size container -->


      <!-- font size container -->
      <div class="single-paramters-section font-sizes-container margin-top-16" *ngIf="selectedTextType != 'localisationStyles'">
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Taille de la police</p>
        
        <div class="flex">
        
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'fontSize')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'fontSize')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].fontSize">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>

          <div class="font-types-input flex">
            <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].bold}"
              (click)="selectFontStyle(selectedTextType, 'bold')">
              G
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].italic}"
              (click)="selectFontStyle(selectedTextType, 'italic')">
              I
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].underline}"
              (click)="selectFontStyle(selectedTextType, 'underline')">
              U
            </span>
          </div>

        </div>

      </div>
      <!-- END font size container -->


      <!-- font size container -->
      <div class="single-paramters-section alignment-and-spacing-container flex margin-top-16">
        
        <!-- Retrait à gauche -->
        <div class="margin-right-8">
          <p class="ag-text-12 no-margin-bottom">Retrait à gauche</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginLeft')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginLeft')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginLeft">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Retrait à gauche -->

        <!-- Espacement avant -->
        <div class="espacement-avant margin-right-8">
          <p class="ag-text-12 no-margin-bottom margin-bottom-4">Espacement avant</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginTop')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginTop')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginTop">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Espacement avant -->

        <!-- Espacement après -->
        <div class="espacement-apres">
          <p class="ag-text-12 no-margin-bottom margin-bottom-4">Espacement après</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginBottom')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginBottom')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginBottom">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Espacement après -->

      </div>

      <!-- Align text with parent title -->
      @if(['normalStyles','localisationStyles'].includes(selectedTextType)){
      <div class="single-paramters-section align-text-with-parent-container margin-top-16">
        <div class="flex pointer" (click)="toggleAlignWithParent()">
          <input type="checkbox" class="no-margin-top" [checked]="formData[selectedTextType].alignWithParentText">
          <p class="no-margin-bottom padding-left-15">Aligner le texte avec le titre parent</p>
        </div>
      </div>
      }
      <!-- END Align text with parent title -->
      

      <!-- Affichage container (only for localisation) -->
      <div class="single-paramters-section affichage-container margin-top-16" *ngIf="selectedTextType == 'localisationStyles'">
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Affichage</p>

        <div class="">
          <ul>
            <li class="pointer" (click)="selectLocalizationAffichage('before')">
              <input type="radio" [checked]="formData.localisationStyles.showAfterDescription == 'before'">
              <span class="ag-text-primary">Avant la description</span>
            </li>
            <li class="pointer" (click)="selectLocalizationAffichage('after')">
              <input type="radio" [checked]="formData.localisationStyles.showAfterDescription == 'after'">
              <span class="ag-text-primary">Après la description</span>
            </li>
          </ul>
        </div>
        

      </div>
      <!-- END Affichage container  -->
      

      <!-- colors container  -->
      <div class="single-paramters-section colors-container margin-top-16">
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Couleur</p>

        <div class="">
          <ul>
            <li (click)="selectColor('')">
              <input type="radio">
              <span class="ag-text-primary">Thème du document</span>
            </li>
            <li (click)="selectColor('black')">
              <input type="radio">
              <span class="ag-text-primary">Noir</span>
            </li>
            <li>
              <input type="radio">
              <span class="ag-text-primary">Couleur personnalisée</span>
            </li>
          </ul>
        </div>

        <!-- textarea container -->
        <div class="color-input-container flex center-items pointer" (click)="colorSelector.toggle($event)">
          <div class="color-preview margin-right-8" [style.backgroundColor]="formData[selectedTextType].color"></div>
          <div class="color-value">
            <span>{{ formData[selectedTextType].color }}</span>
          </div>
        </div>
        <!-- END textarea container -->

      </div>
      <!-- END colors container  -->
      

      <!-- action Buttons -->
      <div class="action-buttons-container text-center margin-top-16">
        <button class="ag-btn-tertiary-sm margin-right-24" (click)="cancelCurrentChanges()">Annuler</button>
        <button class="ag-btn-primary-xs" (click)="emitSaveStylesAndText()">Enregistrer</button>
      </div>
      <!-- END action Buttons -->


    </div>
    <!-- END input values side -->

  </div>


</div>



<!-- Modals -->
<!-- Color selector -->
<p-overlayPanel #colorSelector [showCloseIcon]="true" [appendTo]="'body'" >
  <color-sketch 
    width='200px' 
    [color]="formData[selectedTextType].color"
    (onChangeComplete)="changeColor($event.color.hex)"
  ></color-sketch>
  <!-- 
  [color]="docData.color" 
  (onChangeComplete)="changeColor($event.color.hex)" 
  [presetColors]="docData.colorHistory" 
-->
</p-overlayPanel>
<!-- END Color selector -->

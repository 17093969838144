import { Component, Input, Output, EventEmitter } from '@angular/core';
// modules
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-architect-paramter-sommaire',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './architect-paramter-sommaire.component.html',
  styleUrl: './architect-paramter-sommaire.component.scss'
})
export class ArchitectParamterSommaireComponent {
  
  @Input() sommaire: any = {
    title: "Sommaire",
    fontSize: 16,
    bold: true,
    italic: false,
    underline: false,
    // title 1
    title1Styles: {
      fontSize: 10,
      bold: true,
      italic: false,
      underline: false,
      // margins  
      marginLeft: 0,// Retrait à gauche
      marginTop: 8,// Espacement avant
      marginBottom: 0,// Espacement après
    },
    // title 2 
    title2Styles: {
      fontSize: 10,
      bold: true,
      italic: false,
      underline: false,
      // margins  
      marginLeft: 5,// Retrait à gauche
      marginTop: 0,// Espacement avant
      marginBottom: 0,// Espacement après
    },
    // title 3 
    title3Styles: {
      fontSize: 10,
      bold: true,
      italic: false,
      underline: false,
      // margins  
      marginLeft: 10,// Retrait à gauche
      marginTop: 0,// Espacement avant
      marginBottom: 0,// Espacement après
    },
    // title 4 
    title4Styles: {
      fontSize: 10,
      bold: true,
      italic: false,
      underline: false,
      // margins  
      marginLeft: 15,// Retrait à gauche
      marginTop: 0,// Espacement avant
      marginBottom: 0,// Espacement après
    },
    // title 5 
    title5Styles: {
      fontSize: 10,
      bold: true,
      italic: false,
      underline: false,
      // margins  
      marginLeft: 0,// Retrait à gauche
      marginTop: 20,// Espacement avant
      marginBottom: 0,// Espacement après
    },

  };
  @Output() saveSommaire: EventEmitter<any> = new EventEmitter<any>;

  public selectedTextType: string = "title1Styles";

  public formData: any = {};

  public previewStyles: any = {
    title: {},
    title1Styles: {},
    title2Styles: {},
    title3Styles: {},
    title4Styles: {},
    title5Styles: {},
  }

  ngOnInit(){
    this.formData = JSON.parse(JSON.stringify(this.sommaire));
    this.formatPreviewStyles();
  }

  public selectTextType(type){
    this.selectedTextType = type;
  }
  public selectFontStyle(path, key){
    // localisation label
    if(path == "title"){      
      // toggle value
      const currentValue = this.formData[key];
      this.formData[key] = !currentValue;    

    }else {
      const currentValue = this.formData[path][key];
      this.formData[path][key] = !currentValue;
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  public increaseValue(path, key){
    // localisation label
    if(path == "title"){
      let currentValue = this.formData[key];

      if(currentValue < 100){
        this.formData[key]+=1;
      }
    }else {
      let currentValue = this.formData[path][key];
      
      if(currentValue < 100){
        this.formData[path][key]+=1;
      }
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  public decreaseValue(path, key){
    // localisation label
    if(path == "title"){
      let currentValue = this.formData[key];
      
      if(currentValue > 0){
        this.formData[key]-=1;
      }
    }else {
      let currentValue = this.formData[path][key];
      if(currentValue > 0){
        this.formData[path][key]-=1;
      }
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  private formatPreviewStyles(){
    for(let key in this.formData){
      // let currentSection = this.formData[key];
      
      if(typeof this.formData[key] != "object"){
        const mapped = this.previewStylesMapper(key, this.formData[key]);
        this.previewStyles.title = {...this.previewStyles.title, ...mapped};

      }else{
        for(let sectionKey in this.formData[key]){
          const currectSection  = this.formData[key];
          
          for(let sectionKey in currectSection){
            const mapped = this.previewStylesMapper(sectionKey, currectSection[sectionKey]);
            this.previewStyles[key] = {...this.previewStyles[key], ...mapped};
          }

        }
      }
      
    }

  }

  private previewStylesMapper(key, value){
    const mapper = {
      marginLeft: 'margin-left',
      marginTop: 'margin-top',
      marginBottom: 'margin-bottom',
      fontSize: 'font-size',
      bold: (value) => ({'font-weight': value ? 'bold' : ''}),
      italic: (value) => ({'font-style': value ? 'italic' : ''}),
      underline: (value) =>  ({'text-decoration': value ? 'underline' : ''}),
      color: 'color',
    };

    let mappedKey;

    if(key in mapper){
      // string
      if(typeof mapper[key] == "string"){
        mappedKey = mapper[key];
      }else{ // object
        return mapper[key](value);
      }
    }
    
    // if value is integer & it's greater than 0, convert it to pixel
    if(typeof value === "number"){
      value = `${this.transformPointToPixel(value)}px`;
    }

    // return `${mappedKey}:${value}`;
    return {[mappedKey]: value};
  }
  private transformPointToPixel(point){
    let pixel = point / 72 * 96;

    return pixel.toFixed(2);
  }
  
  public cancelCurrentChanges(){
    this.formData = JSON.parse(JSON.stringify(this.sommaire));
  }
  public emitsaveSommaire(){
    this.saveSommaire.emit({key: 'sommaire', data: this.formData});
  }

}

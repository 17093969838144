<div class="login-container" *ngIf="step=='email-validation'">
    <div class="logo">
      <img src="/assets/aglo_icone.svg">
    </div>
  
    <span class="title">Rédigez des <span class="blue-color">CCTP & DPGF </span> fiables, collaboratifs, bas carbone.</span>
    <p class="subtitle">5 jours d’essai. Aucune carte de crédit requise.</p>
  
    <form [formGroup]="subscriptionForm" (ngSubmit)="focusOutEmail(true)">
      <div class="input-container">
        <label>Email</label>
        <input
        type="email"
        placeholder="Email"
        formControlName="email" (focusout)="focusOutEmail(false)"
        />     
        <!-- (blur)="markFormAsTouched()" -->
      <small *ngIf="formErrors.email" class="text-danger">
        {{ formErrors.email }}
      </small>
      <small *ngIf="!validEmail && subscriptionForm.valid" class="text-danger">
        Email déja existant
      </small>
     </div>
  
      <button type="submit" [disabled]="!validEmail"  class="primary-button">Commencer gratuitement</button>
      
      <div class="flex center-items">
       <div style="height: 1px;width: 45%;background-color:rgba(224, 224, 224, 1)"></div>
       <div class="divider">ou</div>
       <div style="height: 1px;width: 45%;background-color:rgba(224, 224, 224, 1)"></div>
      </div>
  
      <button type="button" class="google-button" (click)="subscribeWithOpenId()">
        <!-- <img src="https://img.icons8.com/color/24/google-logo.png" alt="Google logo" /> -->
        <img src="/assets/google_icon.svg" alt="Google logo" />
        Se connecter avec Google
      </button>
    </form>
  
    <div class="footer">
      <div>
        <p>Connexion possible avec les identifiants</p>
      </div>
      <div>
        <img src="/assets/bam-archi-icon.svg" alt="BAM Archi logo" />
      </div>
    </div>
  
    <p class="login-link">Vous avez déjà un compte ? <span [routerLink]="['/']" >Se connecter</span></p>
</div>
  

  <div class="signup-container" *ngIf="step!='email-validation'">
    <div class="col-md-8 form-section">
      <img src="assets/aglo_icone.svg" alt="AGLO Logo" class="logo" />
  
      <h2 *ngIf="step == 'create-userAccount'">Créez votre compte</h2>
  
      <form [formGroup]="signupForm" (ngSubmit)="register()" [ngClass]="{ 'hidden': step == 'create-userAccount' }">
        <div class="input-group-second width-422">
          <div class="padding-right-18">
            <label for="Prénom">Prénom</label>
            <br>
            <input class="input-design" formControlName="firstName" type="text" placeholder="Prénom" required />
          </div>
          <div>
            <label for="Nom">Nom</label>
            <br>
            <input class="input-design" formControlName="lastName" type="text" placeholder="Nom" required />
          </div>
        </div>
        <div class="width-422">
          <label for="Email">Email</label>
          <input class="input-design" formControlName="email" type="text" placeholder="Email"  [disabled]="isEmailDisabled()" required />
        </div>
        <div class="width-422" *ngIf="!userToken">
          <label for="Entreprise">Entreprise</label>
          <input class="input-design" formControlName="name" type="text" placeholder="Entreprise" required />
        </div>

        <div class="phone-input-container width-422">
          <label for="Numéro de téléphone">Numéro de téléphone</label>
          <div class="country-dropdown" (click)="toggleDropdown()">
            <span>{{ selectedCountry.emoji }}</span>
            <span class="arrow">▼</span>
            <span>{{ selectedCountry.dial_code }}</span>
          </div>
          <div *ngIf="isDropdownOpen" class="dropdown-phone">
            <div
              *ngFor="let country of countries_codes"
              class="dropdown-item"
              (click)="selectCountry(country)"
            >
              <span>{{ country.emoji }}</span>
              <span>{{ country.dial_code }}</span>
            </div>
          </div>

          <input id="phoneId" class="padding-left-88"  name="phone" formControlName="phone" type="tel" class="form-control input-design" placeholder="Numéro de téléphone" >

        </div>
        <div class="width-422">
          <label for="Mot de passe">Mot de passe</label>
          <div class="password-container">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              formControlName="password"
              placeholder="Enter your password"
              class="password-input input-design"
            />
            <img class="eye-icon" src="/assets/eye-icon.svg" (click)="togglePasswordVisibility()"/>

        </div>
        </div>
        
        <div class="terms cursor">
          <input class="margin0" type="checkbox" formControlName="cgu" />
          <span>
            J'accepte les <a href="#">conditions générales d'utilisation</a> et les
            <a href="#">Conditions Générales de Vente d'AGLO</a>
          </span>
        </div>
        <div class="flex justify-end">
          <button type="submit" [disabled]="signupForm.invalid" >Continuer</button>
        </div>
      </form>
      <form [formGroup]="signupSecondForm" (ngSubmit)="save(2)" [ngClass]="{ 'hidden': step =='second-step' }">
        <div class="question">Quelle est l’activité principale de votre entreprise ?</div>
        <div class="options activity-options">
          <div class="options activity-options">
            <div *ngFor="let activity of activities" 
                 class="select-option"
                 [ngClass]="{'selected': signupSecondForm.get('typeStructure')?.value === activity}"
                 (click)="selectOption('typeStructure', activity)">
              <span>{{ activity }}</span>  
            </div>
          </div>
        </div>
        <div *ngIf="signupSecondForm.get('typeStructure')?.value && 
        (signupSecondForm.get('typeStructure')?.value !== 'Formateur/Enseignant 👩‍🏫' && 
         signupSecondForm.get('typeStructure')?.value !== 'Étudiant ‍👨‍🎓')">

          <div class="question">Quelle est la taille de votre entreprise ?</div>
          <div class="options activity-options">
            <div class="options activity-options">
              <div *ngFor="let size of sizes" 
                   class="select-option"
                   [ngClass]="{'selected': signupSecondForm.get('taille_entreprise')?.value === size.value}"
                   (click)="selectOption('taille_entreprise', size.value)">
                <span>{{ size.display }}</span>  
              </div>
            </div>
          </div>
          <!-- <div class="options activity-options" >
            <label *ngFor="let size of sizes" class="radio-option">
              <input 
              type="radio" 
              name="taille_entreprise" 
              [value]="size.value" 
              formControlName="taille_entreprise">
              <span>
                {{ size.display }}
              </span>
            </label>
          </div> -->
        </div>
        <div class="flex justify-end">
          <button type="submit" [disabled]="signupSecondForm.invalid" >Continuer</button>
        </div>
      </form>
      <form [formGroup]="signupThirdForm" (ngSubmit)="save(3)" [ngClass]="{ 'hidden': step =='third-step' }">
        <!-- <div class="question">Quel est votre rôle au sein de l’entreprise ?</div>
        <div class="options activity-options">
          <label *ngFor="let role of roles" class="radio-option">
            <input 
              type="radio" 
              name="role_user" 
              formControlName="role_user"
              [value]="role">
          <span>
            {{ role }}
          </span>  
          </label>
        </div> -->
        <!-- <div class="options activity-options">
          <div class="options activity-options">
            <div *ngFor="let role of roles" 
                 class="select-option"
                 [ngClass]="{'selected': signupThirdForm.get('role_user')?.value === role}"
                 (click)="selectOptionThirstStep('role_user', role)">
              <span>{{ role }}</span>  
            </div>
          </div>
        </div> -->
          <div class="question">Combien de DCE réalisez-vous par an ?</div>
          <!-- <div class="options activity-options" >
            <label *ngFor="let dce of quantityDcePerYear" class="radio-option">
              <input 
              type="radio" 
              name="dce_annuel" 
              [value]="dce.value" 
              formControlName="dce_annuel">
              <span>
                {{ dce.display }}
              </span>
            </label>
          </div> -->
          <div class="options activity-options">
            <div class="options activity-options">
              <div *ngFor="let dce of quantityDcePerYear" 
                   class="select-option"
                   [ngClass]="{'selected': signupThirdForm.get('dce_annuel')?.value === dce.value}"
                   (click)="selectOptionThirstStep('dce_annuel', dce.value)">
                <span>{{ dce.display }}</span>  
              </div>
            </div>
          </div>
        <div class="question">Combien de collaborateurs rédigent les DCE dans votre organisation ?</div>
        <!-- <div class="options activity-options">
          <label *ngFor="let quantity of quantityCollabsForDce" class="radio-option">
            <input 
              type="radio" 
              name="nb_collaborateur_dce" 
              formControlName="nb_collaborateur_dce"
              [value]="quantity.value">
          <span>
            {{ quantity.display }}
          </span>  
          </label>
        </div> -->
        <div class="options activity-options">
          <div class="options activity-options">
            <div *ngFor="let quantity of quantityCollabsForDce" 
                 class="select-option"
                 [ngClass]="{'selected': signupThirdForm.get('nb_collaborateur_dce')?.value === quantity.value}"
                 (click)="selectOptionThirstStep('nb_collaborateur_dce', quantity.value)">
              <span>{{ quantity.display }}</span>  
            </div>
          </div>
        </div>
        <div class="question">Pour finir, comment vous avez-vous connu AGLO ?</div>
        <div class="options activity-options">
          <div class="options activity-options">
            <div *ngFor="let provonance of provonances" 
                 class="select-option"
                 [ngClass]="{'selected': signupThirdForm.get('discoveryMethod')?.value === provonance}"
                 (click)="selectOptionThirstStep('discoveryMethod', provonance)">
              <span>{{ provonance }}</span>  
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button type="submit" [disabled]="signupThirdForm.invalid" >Continuer</button>
        </div>
      </form>
    </div>
  
    <div class="illustration-section">
      <img src="/assets/etape 1.svg" *ngIf="step== 'create-userAccount'" alt="Illustration" />
      <img src="/assets/etape 2.svg" *ngIf="step== 'second-step'" alt="Illustration" />
      <div class="image-design" *ngIf="step== 'third-step'">
        <div class="flex justify-end">
          <img src="/assets/third-step-1.svg" alt="Top Review" class="image top-image" style="width: 280px;" />
        </div>
        <div class="flex justify-end">
          <img src="/assets/third-step-2.svg" alt="App Screenshot" class="image middle-image" style="width: 383px;" />
        </div>
        <div class="flex">
          <img src="/assets/third-step-3.svg" alt="Bottom Review" class="image bottom-image" style="width: 383px;" />

        </div>  
      </div>
      
    </div>
  </div>

  <!-- <div class="signup-container" *ngIf="step== 'second-step'">
      <div class="question">Quelle est l’activité principale de votre entreprise ?</div>
      <div class="options activity-options">
        <label *ngFor="let activity of activities" class="radio-option">
          <input 
            type="radio" 
            name="activity" 
            [value]="activity" 
            [(ngModel)]="agencyActivity">
          {{ activity }}
        </label>
      </div>
      <div class="question">Quelle est la taille de votre entreprise ?</div>
      <div class="options activity-options" *ngIf="agencyActivity && (agencyActivity!='Formateur/Enseignant' && agencyActivity != 'Étudiant') ">
        <label *ngFor="let size of sizes" class="radio-option">
          <input 
          type="radio" 
          name="size" 
          [value]="size" 
          [(ngModel)]="selectedSize">
          {{ size }}
        </label>
      </div>
      <div class="button-container">
        <button (click)="submitForm()">Continuer</button>
      </div>     
      
      <div class="illustration-section">
        <img src="/assets/etape 1.svg" alt="Illustration" />
      </div>
      
    </div> -->
  

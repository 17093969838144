import { Component, Input, Output, EventEmitter } from '@angular/core';
// modules
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-architect-paramter-styles-and-text',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './architect-paramter-styles-and-text.component.html',
  styleUrl: './architect-paramter-styles-and-text.component.scss'
})
export class ArchitectParamterStylesAndTextComponent {

  @Input() stylesAndText: any = {};
  @Output() saveStylesAndText: EventEmitter<any> = new EventEmitter<any>();

  public selectedTextType: string = "heading1styles";

  public formData: any = {};
  public previewStyles: any = {
    normalStyles: {},
    localisationStyles: {
      //   label: {},
      //   content: {},
    },
    heading1styles: {},
    heading2styles: {},
    heading3styles: {},
    heading4styles: {},
    heading5styles: {},
  };

  ngOnInit(){
    this.formData = JSON.parse(JSON.stringify(this.stylesAndText));

    this.formatPreviewStyles();
  }

  public selectTextType(type){
    this.selectedTextType = type;
  }

  public increaseValue(path, key){
    // localisation label
    if(path == "localisationStylesLabel"){
      let currentValue = this.formData.localisationStyles.label[key];

      if(currentValue < 100){
        this.formData.localisationStyles.label[key]+=1;
      }
      console.log(this.formData.localisationStyles.label[key]);
    }else if(path == "localisationStylesContent"){ // localisation content
      let currentValue = this.formData.localisationStyles.content[key];
      
      if(currentValue < 100){
        this.formData.localisationStyles.content[key]+=1;
      }
      console.log(this.formData.localisationStyles.content[key]);
    }else {
      let currentValue = this.formData[path][key];
      
      if(currentValue < 100){
        this.formData[path][key]+=1;
      }
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  public decreaseValue(path, key){
    // localisation label
    if(path == "localisationStylesLabel"){
      let currentValue = this.formData.localisationStyles.label[key];
      if(currentValue > 0){
        this.formData.localisationStyles.label[key]-=1;
      }

    }else if(path == "localisationStylesContent"){ // localisation content
      let currentValue = this.formData.localisationStyles.content[key];
      if(currentValue > 0){
        this.formData.localisationStyles.content[key]-=1;
      }
    }else {
      let currentValue = this.formData[path][key];
      if(currentValue > 0){
        this.formData[path][key]-=1;
      }
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  
  public selectFontStyle(path, key){
    // localisation label
    if(path == "localisationStylesLabel"){      
      // toggle value
      const currentValue = this.formData.localisationStyles.label[key];
      this.formData.localisationStyles.label[key] = !currentValue;    

    }else if(path == "localisationStylesContent"){ // localisation content      
      // toggle value
      const currentValue = this.formData.localisationStyles.label[key];
      this.formData.localisationStyles.content[key] = !currentValue;
    }else {
      const currentValue = this.formData[path][key];
      this.formData[path][key] = !currentValue;
    }

    // apply change to preview
    this.formatPreviewStyles();
  }
  public toggleAlignWithParent(){
    const selectedTextTypeKey = this.selectedTextType;
    let currentValue = this.formData[selectedTextTypeKey].alignWithParentText;

    this.formData[selectedTextTypeKey].alignWithParentText = !currentValue;

    // apply change to preview
    this.formatPreviewStyles();
  }
  public selectLocalizationAffichage(value){
    this.formData.localisationStyles.showAfterDescription = value;
  }
  public changeColor(hexColor){
    const currentSelectedTextTypeKey = this.selectedTextType;
    this.formData[currentSelectedTextTypeKey].color = hexColor;

    // apply change to preview
    this.formatPreviewStyles();
  }
  public selectColor(color){
    const currentSelectedTextTypeKey = this.selectedTextType;
    this.formData[currentSelectedTextTypeKey].color = color;

    // apply change to preview
    this.formatPreviewStyles();
  }

  private formatPreviewStyles(){

    for(let sectionKey in this.formData){
      let currentSection = this.formData[sectionKey];      

      for(let key in currentSection){
        // localisationStyles label & content 
        if(typeof currentSection[key] == "object"){ 
          
          for(let childkey in currentSection[key]){
            let currentNestedSection = currentSection[key];
            
            const mapped = this.previewStylesMapper(childkey, currentNestedSection[childkey]);
          
            this.previewStyles[sectionKey][key] = {...this.previewStyles[sectionKey][key], ...mapped };
          }
          
        }else{
          const mapped = this.previewStylesMapper(key, currentSection[key]);
          // push new object 
          this.previewStyles[sectionKey] = {...this.previewStyles[sectionKey], ...mapped };
        }

      }
      
    }

  }
  private previewStylesMapper(key, value){
    const mapper = {
      marginLeft: 'margin-left',
      marginTop: 'margin-top',
      marginBottom: 'margin-bottom',
      fontSize: 'font-size',
      bold: (value) => ({'font-weight': value ? 'bold' : ''}),
      italic: (value) => ({'font-style': value ? 'italic' : ''}),
      underline: (value) =>  ({'text-decoration': value ? 'underline' : ''}),
      color: 'color',
    };

    let mappedKey;

    if(key in mapper){
      // string
      if(typeof mapper[key] == "string"){
        mappedKey = mapper[key];
      }else{ // object
        return mapper[key](value);
      }
    }
    
    // if value is integer & it's greater than 0, convert it to pixel
    if(typeof value === "number"){
      value = `${this.transformPointToPixel(value)}px`;
    }

    // return `${mappedKey}:${value}`;
    return {[mappedKey]: value};
  }
  private transformPointToPixel(point){
    let pixel = point / 72 * 96;

    return pixel.toFixed(2);
  }

  public cancelCurrentChanges(){
    this.formData = JSON.parse(JSON.stringify(this.stylesAndText));
  }
  public emitSaveStylesAndText(){
    this.saveStylesAndText.emit({key: 'stylesAndText', data: this.formData});
  }

}

<div id="parameters-sommaire">

  <div class="sides-wrapper flex">

    <!-- preview side -->
    <div class="preview-side width-50">

      <div id="preview-page">
        
        <!-- Header text -->
        <div class="header-text-container flex space-between center-items margin-bottom-20">
          <p></p>
          <p class="no-margin">Prévisualisation</p>
        </div>
        <!-- END Header text -->

        <!-- sommaire text container -->
        <div class="sommaire-text-container">
          <h4 class="ag-text-18 ag-weight-400 margin-bottom-16" [ngStyle]="previewStyles.title">{{ formData.title }}</h4>
          <div class="">
            <ul>

              <li class="title1 flex">
                <p class="ag-text-10 ag-weight-700" [ngStyle]="previewStyles.title1Styles">Titre 1</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title2 flex">
                <p class="ag-text-10 ag-weight-700" [ngStyle]="previewStyles.title2Styles">Titre 2</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title3 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title3Styles">Titre 3</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title4 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title4Styles">Titre 4</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title4 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title4Styles">Titre 4</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title5 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title5Styles">Titre 5</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title5 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title5Styles">Titre 5</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title5 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title5Styles">Titre 5</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title2 flex">
                <p class="ag-text-10 ag-weight-700" [ngStyle]="previewStyles.title2Styles">Titre 2</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title3 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title3Styles">Titre 3</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title4 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title4Styles">Titre 4</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title5 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title5Styles">Titre 5</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>
              <li class="title5 flex">
                <p class="ag-text-10" [ngStyle]="previewStyles.title5Styles">Titre 5</p>
                <div class="preview-dashed-border"></div>
                <p>1</p>
              </li>

            </ul>
          </div>
        </div>
        <!-- END sommaire text container -->

      </div>

    </div>
    <!-- END preview side -->

    <!-- input values side -->
    <div class="input-values-side width-50 vertical-items space-between">
      <div class="">

      <h4 class="ag-text-18 ag-weight-700 margin-bottom-8">Sommaire</h4>
      
      <!-- sommaire title container  -->
      <div class="single-paramters-section sommaire-title-container">
        <p class="ag-text-16 ag-weight-700 margin-bottom-8">Titre du sommaire</p>

        <div class="label-input">
          <p class="ag-text-12 no-margin-bottom">Label</p>
          <input type="text" [(ngModel)]="formData.title">
        </div>

      </div>
      <!-- END sommaire title container  -->

        <!-- font size container -->
      <div class="single-paramters-section font-sizes-container margin-top-16">
        <p class="ag-text-12 no-margin-bottom margin-bottom-4">Taille de la police</p>
        
        <div class="flex">
        
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue('title', 'fontSize')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue('title', 'fontSize')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData.fontSize">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>

          <div class="font-types-input flex">
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.bold}" 
              (click)="selectFontStyle('title', 'bold')">
              G
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.italic}" 
              (click)="selectFontStyle('title', 'italic')">
              I
            </span>
            <span class="flex-middle pointer" [ngClass]="{'selected': formData.underline}" 
              (click)="selectFontStyle('title', 'underline')">
              U
            </span>
          </div>

        </div>

      </div>
      <!-- END font size container -->      

      <!-- sommaire title container  -->
      <div class="single-paramters-section sommaire-content-container">
        <p class="ag-text-16 ag-weight-700 margin-bottom-8">Contenu du sommaire</p>

        <!-- Titles headings -->
        <div class="titles-headings-container">
          <ul class="flex no-padding">
            <li [ngClass]="{'selected': selectedTextType == 'title1Styles'}" (click)="selectTextType('title1Styles')">
              <span class="ag-text-16 ag-weight-400">Titre 1</span>
            </li>
            <li [ngClass]="{'selected': selectedTextType == 'title2Styles'}" (click)="selectTextType('title2Styles')">
              <span class="ag-text-16 ag-weight-400">Titre 2</span>
            </li>
            <li [ngClass]="{'selected': selectedTextType == 'title3Styles'}" (click)="selectTextType('title3Styles')">
              <span class="ag-text-16 ag-weight-400">Titre 3</span>
            </li>
            <li [ngClass]="{'selected': selectedTextType == 'title4Styles'}" (click)="selectTextType('title4Styles')">
              <span class="ag-text-16 ag-weight-400">Titre 4</span>
            </li>
            <li [ngClass]="{'selected': selectedTextType == 'title5Styles'}" (click)="selectTextType('title5Styles')">
              <span class="ag-text-16 ag-weight-400">Titre 5</span>
            </li>
          </ul>
        </div>
        
        <!-- sommaire content font -->
        <div class="sommaire-content-font">
          <p class="ag-text-12 no-margin-bottom margin-bottom-4">Taille de la police</p>
          
          <div class="flex">
            <div class="font-size-form flex center-items margin-right-8">
              <div class="arrows-side flex">
                <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'fontSize')" class="margin-bottom-5" id="up-arrow">
                <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'fontSize')">
              </div>
              <div class="input-side">
                <input type="number" [(ngModel)]="formData[selectedTextType].fontSize">
              </div>
              <div class="point-side">
                <p class="no-margin-bottom">pt</p>
              </div>
            </div>

            <div class="font-types-input flex">
              <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].bold}" 
                (click)="selectFontStyle(selectedTextType, 'bold')">
                G
              </span>
              <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].italic}" 
                (click)="selectFontStyle(selectedTextType, 'italic')">
                I
              </span>
              <span class="flex-middle pointer" [ngClass]="{'selected': formData[selectedTextType].underline}" 
                (click)="selectFontStyle(selectedTextType, 'underline')">
                U
              </span>
            </div>
          </div>


        </div>
        <!-- END sommaire content font -->

        <!-- font size container -->
      <div class="single-paramters-section alignment-and-spacing-container flex margin-top-16">
        
        <!-- Retrait à gauche -->
        <div class="margin-right-8">
          <p class="ag-text-12 no-margin-bottom">Retrait à gauche</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginLeft')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginLeft')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginLeft">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Retrait à gauche -->

        <!-- Espacement avant -->
        <div class="espacement-avant margin-right-8">
          <p class="ag-text-12 no-margin-bottom margin-bottom-4">Espacement avant</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginTop')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginTop')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginTop">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Espacement avant -->

        <!-- Espacement après -->
        <div class="espacement-apres">
          <p class="ag-text-12 no-margin-bottom margin-bottom-4">Espacement après</p>
          <div class="font-size-form flex center-items margin-right-8">
            <div class="arrows-side flex">
              <img src="/assets/chevron-bottom.svg" (click)="increaseValue(selectedTextType, 'marginBottom')" class="margin-bottom-5" id="up-arrow">
              <img src="/assets/chevron-bottom.svg" (click)="decreaseValue(selectedTextType, 'marginBottom')">
            </div>
            <div class="input-side">
              <input type="number" [(ngModel)]="formData[selectedTextType].marginBottom">
            </div>
            <div class="point-side">
              <p class="no-margin-bottom">pt</p>
            </div>
          </div>
        </div>
        <!-- END Espacement après -->

      </div>

      </div>

    </div>

      <!-- action Buttons -->
      <div class="action-buttons-container text-center margin-top-16">
        <button class="ag-btn-tertiary-sm margin-right-24" (click)="cancelCurrentChanges()">Annuler</button>
        <button class="ag-btn-primary-xs" (click)="emitsaveSommaire()">Enregistrer</button>
      </div>
      <!-- END action Buttons -->

    </div>
    <!-- END input values side -->

  </div>

</div>
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgSharedModule } from 'src/app/all/ngshared.module';


@Component({
  selector: 'app-architect-paramters-affichage-general',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './architect-paramters-affichage-general.component.html',
  styleUrl: './architect-paramters-affichage-general.component.scss'
})
export class ArchitectParamtersAffichageGeneralComponent {

  @Input() affichageGeneral: any = {
    marginsTitle: "",
    margins: {},
    dateFormat: 'short', // short, long
    documentsTitles: {
      cctp: {
        indexPageTitle: "Cahier des clauses techniques particulières",
        headersAndFootersTitle: "CCTP"
      },
      dpgf: {
        indexPageTitle: "Décomposition du Prix Globale et Forfaitaire",
        headersAndFootersTitle: "DPGF"
      },
      dqe: {
        indexPageTitle: "Décomposition du Prix Globale et Forfaitaire",
        headersAndFootersTitle: "DQE"
      },
      estimation: {
        indexPageTitle: "Décomposition du Prix Globale et Forfaitaire",
        headersAndFootersTitle: "Estimation"
      },
      recapitulatifEestimation: {
        indexPageTitle: "Décomposition du Prix Globale et Forfaitaire",
        headersAndFootersTitle: "Récapitulatif de l’estimation"
      }
    }
  };
  @Output() saveAffichageGeneral: EventEmitter<any> = new EventEmitter<any>;

  public selectedDocumentType:string = "cctp"; // cctp, DPGF, DQE,  Estimation,  Récapitulatif de l’estimation

  public marginsList: any = [
    {title: "Normales", description1: "Haut/Bas : 2,5 cm", description2: "Droite/Gauche : 2,5 cm", values: {top: 70.8, bottom: 70.8, left: 70.8, right: 70.8} },
    {title: "Etroites", description1: "Haut/Bas : 1,27 cm", description2: "Droite/Gauche : 1,27 cm ", values: {top: 36, bottom: 36, left:  36, right: 36} },
    {title: "Larges", description1: "Haut/Bas : 2,54 cm", description2: "Droite/Gauche : 5,08 cm ", values: {top: 72, bottom: 144, left:  72, right: 144} },
  ];

  public selectDocumentType(documentType){
    this.selectedDocumentType = documentType;
  }

  public selectMargin(margin){
    const { title, values } = margin;

    this.affichageGeneral.marginsTitle = title;
    this.affichageGeneral.margins = values;
  }

  public selectDateFormat(format){
    this.affichageGeneral.dateFormat = format;
  }

  public inputChanged(key, value){
    const documentTypeKey = this.selectedDocumentType;
    this.affichageGeneral.documentsTitles[documentTypeKey][key] = value;
  }

  public emitSaveAffichageGeneral(){
    this.saveAffichageGeneral.emit({key: 'affichageGeneral', data: this.affichageGeneral});
  }
  

}
